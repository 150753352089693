import { Guard, isNumber, isType } from "../utils";
import { Goals } from "./goals";
import { FunctionFactory } from 'survey-core';

enum WeightUnit {
  Pounds = "lbs",
  Kilograms = "kg",
}

const weightToggle = {
  name: "weightUnit",
  title: "What is your current weight?",
  description: `Thanks for sharing this with us. We don't mean to pry, we just need to know so we can build a plan that's right for you.`,
  type: 'boolean',
  labelTrue: WeightUnit.Kilograms,
  valueTrue: WeightUnit.Kilograms,
  labelFalse: WeightUnit.Pounds,
  valueFalse: WeightUnit.Pounds,
  defaultValue: WeightUnit.Pounds,
};

const weightQuestion = {
  name: "weight",
  titleLocation: 'hidden',
  descriptionLocation: "underTitle",
  type: "multipletext",
  inputType: "number",
  items: [
    {
      name: "weightRaw",
      title: "{weightUnit}",
      inputType: 'number',
      validators: [{
        type: "numeric",
        minValue: 25,
        text: "Please enter a valid weight.",
      }],
    },
  ],
  isRequired: true,
};

function renderWeightGoalText(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Goals) {
    case Goals.LoseFat:
      return `weight you'll lose`;
    case Goals.BuildMuscle:
      return `muscle you'll build`;
    case Goals.BuildMuscleAndLoseFat:
      return `fat you'll lose and muscle you'll build`;
    default:
      return ``;
  }
}

function renderWeightBaseText(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Goals) {
    case Goals.LoseFat:
      return `lose 1-2 lbs per week`;
    case Goals.BuildMuscle:
      return `gain 1-2 lbs of muscle per month`;
    case Goals.BuildMuscleAndLoseFat:
      return `lose around 0.5-1 lb of fat per week while building muscle`;
    default:
      return ``;
  }
}

FunctionFactory.Instance.register('renderWeightGoalText', renderWeightGoalText);
FunctionFactory.Instance.register('renderWeightBaseText', renderWeightBaseText);

const weightGoalTextExpression = {
  name: "weightGoalText",
  expression: "renderWeightGoalText({goals})",
};

const weightBaseTextExpression = {
  name: "weightBaseText",
  expression: "renderWeightBaseText({goals})",
};

type Weight = {
  weightRaw: number;
}
const isWeight: Guard<Weight> = isType<Weight>({ weightRaw: isNumber });

function getWeightPounds(weight: number, weightUnit: WeightUnit) {
  return Math.round(weightUnit === WeightUnit.Kilograms ? weight * 2.20462 : weight);
}

function getWeightKg(weight: number, weightUnit: WeightUnit) {
  return Math.round(weightUnit === WeightUnit.Kilograms ? weight : weight / 2.20462);
}

export {
  isWeight,
  weightToggle,
  WeightUnit,
  weightQuestion,
  weightGoalTextExpression,
  weightBaseTextExpression,
  getWeightPounds,
  getWeightKg,
}