import styled from "styled-components";
import LoadingBar from "./LoadingBar";
import { User } from "../types";
import { Goals } from "../quiz/goals";
import { useMemo } from "react";
import { PageContainer } from "../common-ui/PageContainer";
import { CDN_ROOT } from "./images";

const BodyWrapper = styled.div`
  width: 100%
  body {
    background: #eee;
    /* This is just a helper in case the element has a transparent background or white colors. */
  }

  .sd-title {
    color: #1e1e1e;
    flex-direction: row;
    white-space: normal;
    word-break: break-word;
  }

  * {
    scrollbar-color: #d6d6d6 transparent;
    scrollbar-width: thin;
  }
`;

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  justify-content: center;
  background-color: #add8e6;
  padding-top: 0px;
`;

const LoadingImage = styled.img`
  max-width: 400px;
  width: 100%;
  height: auto;
  margin-bottom: 26px;
`;

const Subtitle = styled.h2`
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  color: #1E1E1E;
  text-align: center;
  max-width: 500px;
  .company-name {
    white-space: nowrap;
  }
`;

const loadingMessages = ["", "Analyzing responses...", "Calculating goal pace...", "Preparing results..."];

type LoadingPageProps = {
  user: User,
  onComplete: () => void;
}
const LoadingPage = ({ user, onComplete }: LoadingPageProps) => {
  const loadingGoalText = useMemo(() => {
    switch (user.goals) {
      case Goals.LoseFat:
        return "Lose weight";
      case Goals.BuildMuscle:
        return "Build muscle";
      case Goals.BuildMuscleAndLoseFat:
        return "Lose Fat and Build Muscle";
    }
  }, [user]);

  return (
    <BodyWrapper>
      <StyledPageContainer>
        <Subtitle>{loadingGoalText} faster with <span className='company-name'>Built With Science</span> <div>than vs. trying on your own</div></Subtitle>
        <LoadingImage src={`${CDN_ROOT}/images/bws-loading-chart-2.jpg`} alt="Personal progress comparison with BWS" />
        <LoadingBar strings={loadingMessages} onComplete={onComplete} />
      </StyledPageContainer>
    </BodyWrapper>
  )
};

export {
  LoadingPage,
}
