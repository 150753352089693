import { FunctionFactory } from 'survey-core';
import { BodyType } from '../quiz/bodytype';

const bodyTypeSplashHtml = `
<div class="quote-container">
  <div class="quote">
    {bodyTypeQuote}
  </div>
</div> 
`;

function renderBodyTypeSplash(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as BodyType) {
    case BodyType.Skinny:
      return `<div>Thanks for sharing that with us.</div>
<p>We'll help you take a gradual, sustainable approach towards your goals.</p>`;
    case BodyType.SkinnyFat:
      return `<div>This can be a tricky body type to navigate.</div>
<p>Your body needs to build more muscle but also need to lose fat, especially in stubborn areas. But you're in good hands. We've helped thousands of others just like you <span class="bold">transform from skinny fat to lean, muscular, and athletic.</span></p>`;
    case BodyType.Bulky:
      return `<div>Believe it or not, you're in a really good position.</div>
<p>You've done the hard work of building muscle. Lowering your body fat without losing your hard earned muscle will help you build a lean, muscular, athletic physique.</p>`;
    case BodyType.Overweight:
      return `<div>Thanks for sharing that with us.</div>
<p>We'll help you take a gradual, sustainable approach towards your goals. We want to help you lose the fat, and keep it off for good.</p>`
  }
}

const bodyTypeSplashExpression = {
  name: "bodyTypeQuote",
  expression: "bodyTypeSplashText({bodyType})",
};

FunctionFactory.Instance.register("bodyTypeSplashText", renderBodyTypeSplash);

const bodyTypeSplashElement = {
  visibleIf: `{bodyType} != "${BodyType.Skinny}"`,
  name: "body-type-splash",
  type: "html",
  html: bodyTypeSplashHtml,
}

export {
  bodyTypeSplashElement,
  bodyTypeSplashExpression,
}
