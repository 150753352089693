import { FunctionFactory } from 'survey-core';
import { TimeAvailable } from '../quiz/timeAvailable';

const timeAvailableSplashHtml = `
<div class="quote-container"> 
  <img src="https://cdn.builtwithscience.com/images/splash/time{gender}.jpg" alt="Testimonial from a user like you!" />
  <div class="quote">
    {timeAvailableQuote}
  </div>
</div>`;

const timeAvailableSplashExpression = {
  name: "timeAvailableQuote",
  expression: "renderTimeAvailableQuote({timeAvailable})",
};

function renderTimeAvailableQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (String(params[0]) as TimeAvailable) {
    case TimeAvailable.SixtyMinutes:
    case TimeAvailable.NinetyMinutes: {
      return `Awesome! That's more than enough time. If you're ever short on time, we'll also give you "time-saving workouts" that'll get you the same gains in far less time (30 minutes or less).`;
    }
    case TimeAvailable.ThirtyMinutes:
    case TimeAvailable.FourtyFiveMinutes:
    default: {
      return `No problem! We use science to make shorter workouts (some as quick as 15 minutes) just as effective as longer ones.`;
    }
  }
}

FunctionFactory.Instance.register("renderTimeAvailableQuote", renderTimeAvailableQuote);

const timeAvailableSplashElement = {
  name: 'time-available-splash',
  type: 'html',
  html: timeAvailableSplashHtml,
};

export {
  timeAvailableSplashElement,
  timeAvailableSplashExpression,
}
