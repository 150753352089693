import { FunctionFactory } from 'survey-core';
import { Goals } from '../quiz/goals';

const goodHandsHtml = `<div class="quote-container">
  <h1>
    You're not alone.
  </h1>
  <div class="quote">
    We've helped <span class="bold">110,247 {genderNoun}</span> like you {goodHandsGoalText}!
  </div>
</div>`;

const goodHandsSplashElement = {
  name: 'good-hands-splash',
  type: 'html',
  html: goodHandsHtml,
}

function renderGoodHandsGoalsText(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Goals) {
    case Goals.LoseFat:
      return "lose weight";
    case Goals.BuildMuscle:
      return "build lean muscle";
    case Goals.BuildMuscleAndLoseFat:
      return "build muscle while losing fat";
  }
}

const goodHandsGoalsTextExpression = {
  name: "goodHandsGoalText",
  expression: "renderGoodHandsGoalsText({goals})",
};

FunctionFactory.Instance.register("renderGoodHandsGoalsText", renderGoodHandsGoalsText);

export {
  goodHandsSplashElement,
  goodHandsGoalsTextExpression,
}
