import { activityQuestion } from "./quiz/activity";
import { ageQuestion } from "./quiz/age";
import { previouslymemberQuestion } from "./quiz/previouslymember";
import { areaBodyQuestion, areaBodyGenderExpression } from "./quiz/areaBody";
import { bodyFatMaleQuestion, bodyFatFemaleQuestion } from "./quiz/bodyfat";
import { bodyTypeMaleQuestion, bodyTypeFemaleQuestion } from "./quiz/bodytype";
import { busyLevelQuestion } from "./quiz/busy";
import { caloriesQuestion, caloriesTextExpression, goalCaloriesTextExpression } from "./quiz/calories";
import { comfortQuestion } from "./quiz/comfort";
import { consistencyQuestion } from "./quiz/consistency";
import { describeSelfQuestion } from "./quiz/describeSelf";
import { dietQuestion } from "./quiz/diet";
import { dissatisfactionQuestion } from "./quiz/dissatisfaction";
import { durationQuestion } from "./quiz/duration";
import { emailQuestion, emailWeightGoalTextExpression } from "./quiz/email";
import { equipmentQuestion } from "./quiz/equipment";
import { experienceQuestion } from "./quiz/experience";
import { focusQuestion } from "./quiz/focus";
import { genderQuestion, genderNounExpression } from "./quiz/gender";
import { goalBodyFatMaleQuestion, goalBodyFatFemaleQuestion, goalBodyFatDescExpression } from "./quiz/goalBodyFat";
import { muscleGainQuestion, muscleGainTextExpressions } from "./quiz/goalMuscleGain";
import { goalWeightQuestion, validateGoalWeightExpression } from "./quiz/goalWeight";
import { goalsQuestion, goalsTextExpression } from "./quiz/goals";
import { heightToggle, heightQuestion } from "./quiz/height";
import { injuriesQuestion } from "./quiz/injuries";
import { motivationQuestion } from "./quiz/motivation";
import { muscleFocusQuestion } from "./quiz/muscleFocus";
import { nameQuestion, firstNameExpression, lastNameExpression } from "./quiz/name";
import { numberWorkoutsQuestion } from "./quiz/numberWorkouts";
import { nutritionQuestion, nutritionRestrictionsQuestion } from "./quiz/nutrition";
import { otherProgramsQuestion } from "./quiz/otherPrograms";
import { proteinQuestion, goalProteinTextExpression, proteinTextExpression } from "./quiz/protein";
import { scienceQuestion } from "./quiz/scienceFamiliarity";
import { shameQuestion } from "./quiz/shame";
import { studentQuestion } from "./quiz/student";
import { timeAvailableQuestion } from "./quiz/timeAvailable";
import { weightToggle, weightQuestion, weightBaseTextExpression, weightGoalTextExpression } from "./quiz/weight";
import { whyConsistencyQuestion } from "./quiz/whyConsistency";
import { almostThereSplashElement } from "./static/almostThereSplash";
import { bodyTypeSplashElement, bodyTypeSplashExpression } from "./static/bodyTypeSplash";
import { busyLevelSplashElement, busyLevelSplashExpression } from "./static/busyLevelSplash";
import { calorieSplashElement, calorieSplashExpression } from "./static/calorieSplash";
import { comfortSplashElement, comfortSplashExpression } from "./static/comfortSplash";
import { consistencySplashElement, consistencySplashExpression } from "./static/consistencySplash";
import { describeSelfSplashElement, describeSelfSplashExpression } from "./static/describeSelfSplash";
import { dietSplashElement } from "./static/dietSplash";
import { dissatisfactionSplashElement, dissatisfactionImageUrlExpression, dissatisfactionTestimonialDisplayNameExpression } from "./static/dissatisfactionSplash";
import { equipmentSplashElement, equipmentSplashExpression, equipmentSplashImgUrlExpression, equipmentTestimonialsDisplayNameExpression } from "./static/equipmentSplash";
import { goalTargetSplashElement, goalGenderImageUrlExpression, goalGenderTextExpression, goalGenderNameExpression, goalTargetSplashExpression } from "./static/goalTargetSplash";
import { goodHandsSplashElement, goodHandsGoalsTextExpression } from "./static/goodHandsSplash";
import { injuriesSplashElement, injuriesSplashExpression } from "./static/injuriesSplash";
import { muscleFocusSplashElement, muscleFocusImgUrlExpression, muscleFocusQuoteExpression } from "./static/muscleFocusSplash";
import { proteinSplashElement, proteinSplashExpression } from "./static/proteinSplash";
import { scienceSplashElement, scienceSplashExpression } from "./static/scienceSplash";
import { shameSplashElement } from "./static/shameSplash";
import { timeAvailableSplashElement, timeAvailableSplashExpression } from "./static/timeAvailableSplash";
import { whyConsistencySplashElement, whyConsistencySplashExpression } from "./static/whyConsistencySplash";

const BASE_SURVEY = {
  // logo:
  //   "https://builtwithscience.com/wp-content/uploads/2022/12/BuiltWithScience-Blue-1.png",
  // logoWidth: "245px",
  // logoHeight: "80px",
  // logoFit: "cover",
  // logoPosition: "left",
  showQuestionNumbers: "off",
  showPageNumbers: false,
  showProgressBar: "off",
  goNextPageAutomatic: true,
  questionDescriptionLocation: "underTitle",
  requiredText: "", // hides the * next to required questions
  completeText: "See My Results",
  completedHtml: "<h3>Thank you for completing our survey!</h3><button class='sd-btn' style='max-width: 280px; margin: 0 auto;' onClick='window.location.reload();'>Change your answers</button>",
  allowCompleteSurveyAutomatic: false
}

// TODO - Always update the package.json verison number when anything about the quiz changes!
const pages = [
  {
    name: 'goals',
    elements: [goalsQuestion],
  },
  {
    name: 'gender',
    elements: [genderQuestion],
  },
  {
    name: 'age',
    elements: [ageQuestion],
  },
  {
    name: 'height',
    elements: [heightToggle, heightQuestion],
  },
  {
    name: 'weight',
    elements: [weightToggle, weightQuestion],
  },
  {
    name: 'goodHandsSplash',
    elements: [goodHandsSplashElement],
  },
  {
    name: 'name',
    elements: [nameQuestion],
  },
  {
    name: 'bodytype',
    elements: [bodyTypeMaleQuestion, bodyTypeFemaleQuestion],
  },
  {
    name: 'bodyTypeSplash',
    elements: [bodyTypeSplashElement],
  },
  {
    name: 'bodyFat',
    elements: [bodyFatMaleQuestion, bodyFatFemaleQuestion],
  },
  {
    name: 'goalBodyFat',
    elements: [goalBodyFatMaleQuestion, goalBodyFatFemaleQuestion],
  },
  {
    name: 'muscleGain',
    elements: [muscleGainQuestion],
  },
  {
    name: 'goalWeight',
    elements: [goalWeightQuestion],
  },
  {
    name: 'goalTargetSplash',
    elements: [goalTargetSplashElement],
  },
  {
    name: 'describeSelf',
    elements: [describeSelfQuestion],
  },
  {
    name: 'describeSelfSplash',
    elements: [describeSelfSplashElement],
  },
  {
    name: 'whyConsistency',
    elements: [whyConsistencyQuestion],
  },
  {
    name: 'whyConsistencySplash',
    elements: [whyConsistencySplashElement]
  },
  {
    name: 'areaBody',
    elements: [areaBodyQuestion]
  },
  {
    name: 'muscleFocus',
    elements: [muscleFocusQuestion],
  },
  {
    name: 'muscleFocusSplash',
    elements: [muscleFocusSplashElement],
  },
  {
    name: 'experience',
    elements: [experienceQuestion],
  },
  {
    name: 'email',
    elements: [emailQuestion],
  },
  {
    name: 'duration',
    elements: [durationQuestion],
  },
  {
    name: 'frequency',
    elements: [motivationQuestion],
  },
  {
    name: 'shame',
    elements: [shameQuestion],
  },
  {
    name: 'shameSplash',
    elements: [shameSplashElement],
  },
  {
    name: 'dissatisfaction',
    elements: [dissatisfactionQuestion],
  },
  {
    name: 'dissatisfactionSplash',
    elements: [dissatisfactionSplashElement]
  },
  {
    name: 'consistency',
    elements: [consistencyQuestion],
  },
  {
    name: 'consistencySplash',
    elements: [consistencySplashElement]
  },
  {
    name: 'diet',
    elements: [dietQuestion],
  },
  {
    name: 'dietSplash',
    elements: [dietSplashElement]
  },
  {
    name: 'otherPrograms',
    elements: [otherProgramsQuestion]
  },
  {
    name: 'science',
    elements: [scienceQuestion]
  },
  {
    name: `scienceSplash`,
    elements: [scienceSplashElement]
  },
  {
    name: `activity`,
    elements: [activityQuestion]
  },
  {
    name: 'student',
    elements: [studentQuestion]
  },
  {
    name: `comfort`,
    elements: [comfortQuestion]
  },
  {
    name: `comfortSplash`,
    elements: [comfortSplashElement]
  },
  {
    name: `numberWorkouts`,
    elements: [numberWorkoutsQuestion]
  },
  {
    name: `timeAvailable`,
    elements: [timeAvailableQuestion],
  },
  {
    name: `timeAvailableSplash`,
    elements: [timeAvailableSplashElement],
  },
  {
    name: `equipment`,
    elements: [equipmentQuestion]
  },
  {
    name: `equipmentSplash`,
    elements: [equipmentSplashElement]
  },
  {
    name: `injuries`,
    elements: [injuriesQuestion]
  },
  {
    name: `injuriesSplash`,
    elements: [injuriesSplashElement]
  },
  {
    name: `almostThere`,
    elements: [almostThereSplashElement]
  },
  {
    name: `calories`,
    elements: [caloriesQuestion]
  },
  {
    name: `calorieSplash`,
    elements: [calorieSplashElement]
  },
  {
    name: `protein`,
    elements: [proteinQuestion],
  },
  {
    name: `proteinSplash`,
    elements: [proteinSplashElement]
  },
  {
    name: `nutrition`,
    elements: [nutritionQuestion]
  },
  {
    name: `nutritionRestrictions`,
    elements: [nutritionRestrictionsQuestion]
  },
  {
    name: `busyLevel`,
    elements: [busyLevelQuestion]
  },
  {
    name: `busyLevelSplash`,
    elements: [busyLevelSplashElement]
  },
  {
    name: 'focus',
    elements: [focusQuestion]
  }
  
] as const;

type PageName = typeof pages[number]['name'];

const SURVEY_JSON = {
  ...BASE_SURVEY,
  pages,
  calculatedValues: [
    areaBodyGenderExpression,
    bodyTypeSplashExpression,
    busyLevelSplashExpression,
    calorieSplashExpression,
    caloriesTextExpression,
    comfortSplashExpression,
    consistencySplashExpression,
    describeSelfSplashExpression,
    dissatisfactionImageUrlExpression,
    dissatisfactionTestimonialDisplayNameExpression,
    emailWeightGoalTextExpression,
    equipmentSplashExpression,
    equipmentSplashImgUrlExpression,
    equipmentTestimonialsDisplayNameExpression,
    firstNameExpression,
    genderNounExpression,
    ...muscleGainTextExpressions,
    goalBodyFatDescExpression,
    goalCaloriesTextExpression,
    goalGenderImageUrlExpression,
    goalGenderTextExpression,
    goalGenderNameExpression,
    goalProteinTextExpression,
    goalsTextExpression,
    goalTargetSplashExpression,
    goodHandsGoalsTextExpression,
    injuriesSplashExpression,
    lastNameExpression,
    muscleFocusImgUrlExpression,
    muscleFocusQuoteExpression,
    proteinSplashExpression,
    proteinTextExpression,
    scienceSplashExpression,
    timeAvailableSplashExpression,
    validateGoalWeightExpression,
    weightBaseTextExpression,
    weightGoalTextExpression,
    whyConsistencySplashExpression,
  ],
};

export type {
  PageName,
}

export {
  SURVEY_JSON,
}
