import { FunctionFactory } from "survey-core";
import { Goals } from "../quiz/goals";
import { Gender } from "../quiz/gender";
import { getTestimonialByName, TestimonialKey } from "./testimonials";

const goalTargetSplashHtml = `
<div class="quote-container">
  <div class="quote">{goalMuscleGainQuote}</div>
  <div class="quote">{goalGenderText}</div>
  <img src="{goalGenderImageUrl}" alt="Testimonial from a user like you!"/>
  <div class="quote">{goalGenderName} - Verified Built With Science User</div>
</div>`;

const goalTargetSplashExpression = {
  name: "goalMuscleGainQuote",
  expression: "renderGoalTargetSplash({goals})",
};

const muscleGainImageUrlMap = {
  [Goals.LoseFat]: {
    [Gender.Male]: getTestimonialByName("Cesar"),
    [Gender.Female]: getTestimonialByName("Katy"),
  },
  [Goals.BuildMuscle]: {
    [Gender.Male]: getTestimonialByName("Abdullah"),
    [Gender.Female]: getTestimonialByName("Tahnee"),
  },
  [Goals.BuildMuscleAndLoseFat]: {
    [Gender.Male]: getTestimonialByName("Stephen"),
    [Gender.Female]: getTestimonialByName("Salma"),
  },
} as const;

function renderGoalTargetSplash(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Goals) {
    case Goals.LoseFat:
      return `Our members lose weight at an average rate of 0.7% of their bodyweight per week.\nAny faster than this and you risk losing muscle.`;
    case Goals.BuildMuscle:
      return `Depending on your training experience, with the right plan you can expect to gain about 1-3 lbs of muscle per month.`;
    case Goals.BuildMuscleAndLoseFat:
      return `Our members drop their body fat at an average rate of 1-3% per month while building muscle.`; // we want to change this to 2-3%
  }
}

FunctionFactory.Instance.register(
  "renderGoalTargetSplash",
  renderGoalTargetSplash
);

const extractParamValue = (params: any[], prop: TestimonialKey): string => {
  if (params[0] == null || params[1] == null) return "";

  const goals = params[0] as Goals;
  const gender = params[1] as Gender;
  return muscleGainImageUrlMap[goals]?.[gender][prop] ?? "";
};

const renderGoalGenderImageUrl = (params: any[]): string =>
  extractParamValue(params, "image");
const renderGoalGenderText = (params: any[]): string =>
  extractParamValue(params, "testimonial");
const renderGoalGenderName = (params: any[]): string =>
  extractParamValue(params, "displayName");

FunctionFactory.Instance.register(
  "goalGenderImageUrl",
  renderGoalGenderImageUrl
);
FunctionFactory.Instance.register("goalGenderText", renderGoalGenderText);
FunctionFactory.Instance.register("renderGoalGenderName", renderGoalGenderName);

const goalGenderImageUrlExpression = {
  name: "goalGenderImageUrl",
  expression: "goalGenderImageUrl({goals}, {gender})",
};

const goalGenderTextExpression = {
  name: "goalGenderText",
  expression: "goalGenderText({goals}, {gender})",
};

const goalGenderNameExpression = {
  name: "goalGenderName",
  expression: "renderGoalGenderName({goals}, {gender})",
};

const goalTargetSplashElement = {
  name: "goal-target-splash",
  type: "html",
  html: goalTargetSplashHtml,
};

export {
  goalTargetSplashElement,
  goalTargetSplashExpression,
  goalGenderImageUrlExpression,
  goalGenderNameExpression,
  goalGenderTextExpression,
};
