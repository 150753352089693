import { css } from "styled-components";
import { contrastColor } from "../features/utils";

const checkmarkStyle = css`
  &:before {
    content: '\\2714';
    display: inline-block;
    font-weight: bold;
    color: #406EDF;
    padding: .3em .6em;
    margin-right: 20px;
    position: absolute;
    left: 4px;
    top: -6px;
  }
`;

const landingPageListFont = css`
  font-size: 20px;
  @media (max-width: 479px) {
    margin-top: 0;
  }
`

const commonButtonStyle = css`
  font-family: 'Oswald', sans-serif;
  font-size: 18px;
  background-color: #dfb140;
  color: ${() => contrastColor('dfb140')};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: none;

  &:hover {
    color: ${() => contrastColor('406EDF')};
    background-color: #406EDF;
  }

  letter-spacing: -0.03em;
  line-height: 44.8px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 32px;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    min-height: 1px;
    max-width: 100%;
  }

  ${landingPageListFont}

  color: #10181F;

  @media (max-width: 767px) {
    .inherited-styles-for-exported-element {
      text-align: justify;
    }
  }
`;

export {
  commonButtonStyle,
  checkmarkStyle,
  landingPageListFont,
}