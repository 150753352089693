const shameSplashHtml = `
<div class="quote-container"> 
  <div class="quote">
    Thank you for sharing that with us, we know it's not easy. We'll support you every step of the way to your new-found confidence.
  </div>
</div>`;

const shameSplashElement = {
  visibleIf: `{shame} != "No"`,
  name: 'shame-splash',
  type: 'html',
  html: shameSplashHtml,
};

export {
  shameSplashElement,
};