import styled from "styled-components";

const Text = styled.p`
  font-size: 20px;
  color: #1E1E1E;
  text-align: center;
  @media (max-width: 800px) {
    line-height: 1.4;
  }
`;

export {
  Text,
}