enum Experience {
  Beginner = "Beginner",
  Intermediate = "Intermediate",
}

const experienceQuestion = {
  name: "experience",
  title: "How experienced are you in the gym?",
  type: "radiogroup",
  description: "Be honest here. Your program will be more effective if customized to your true experience level.",
  choices: [
    {
      value: Experience.Beginner,
      text: "<strong>Beginner:</strong> I'm either just starting out in the gym or am just starting to take my training and nutrition seriously",
    },
    {
      value: Experience.Intermediate,
      text: "<strong>Intermediate:</strong> I know my way around the gym and have worked out consistently at least 3 times a week for the past year",
    }
  ],
  isRequired: true,
};

export {
  experienceQuestion,
  Experience,
}