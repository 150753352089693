import { FunctionFactory } from 'survey-core';
import { Gender } from '../quiz/gender';
import { getMotivationalByName } from './testimonials';
import { BodyType } from '../quiz/bodytype';

const dissatisfactionSplashHtml = `
<div class="quote-container">
  <div class="quote">
    We get it. It's hard to know what to trust with all the contradicting information out there. We use a proven, science-based approach to guarantee results.
  </div>
  <img src="{dissatisfactionImageUrl}" alt="Testimonial from a user like you!" />
  <div>{dissatisfactionTestimonialDisplayName} - Verified Built With Science User</div>
</div>`;

const dissatisfactionImageUrlMap = {
  [BodyType.SkinnyFat]: {
    [Gender.Male]: getMotivationalByName('Reinis'),
    [Gender.Female]: getMotivationalByName('Salma'),
  },
  [BodyType.Skinny]: {
    [Gender.Male]: getMotivationalByName('Brian'),
    [Gender.Female]: getMotivationalByName('Salma'),
  },
  [BodyType.Overweight]: {
    [Gender.Male]: getMotivationalByName('Adam'),
    [Gender.Female]: getMotivationalByName('Jade'),
  },
  [BodyType.Bulky]: {
    [Gender.Male]: getMotivationalByName('Adam'),
    [Gender.Female]: getMotivationalByName('Jade'),
  }
} as const;

const dissatisfactionImageUrlExpression = {
  name: "dissatisfactionImageUrl",
  expression: "renderDissatisfactionImageUrl({bodyType}, {gender})",
};

function renderDissatisfactionImageUrl(params: any[]): string {
  if (params === undefined || params === null) return "";
  const bodyType = params[0] as BodyType;
  const gender = params[1] as Gender;

  return dissatisfactionImageUrlMap[bodyType]?.[gender].image ?? '';
}

FunctionFactory.Instance.register("renderDissatisfactionImageUrl", renderDissatisfactionImageUrl);

const dissatisfactionTestimonialDisplayNameExpression = {
  name: "dissatisfactionTestimonialDisplayName",
  expression: "renderDissatisfactionTestimonialDisplayName({bodyType}, {gender})",
};

function renderDissatisfactionTestimonialDisplayName(params: any[]): string {
  if (params === undefined || params === null) return "";
  const bodyType = params[0] as BodyType;
  const gender = params[1] as Gender;

  return dissatisfactionImageUrlMap[bodyType]?.[gender].displayName ?? '';
}

FunctionFactory.Instance.register("renderDissatisfactionTestimonialDisplayName", renderDissatisfactionTestimonialDisplayName);

const dissatisfactionSplashElement = {
  visibleIf: `{dissatisfaction} > 2`,
  name: 'dissatisfaction-splash',
  type: 'html',
  html: dissatisfactionSplashHtml,
};


export {
  dissatisfactionSplashElement,
  dissatisfactionImageUrlExpression,
  dissatisfactionTestimonialDisplayNameExpression,
}
