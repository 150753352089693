enum ScienceFamiliarity {
  Very = 'Very',
  Somewhat = 'Somewhat',
  Attempted = 'Attempted',
  NotAtAll = 'Not at all',
}

const scienceQuestion = {
  name: 'science',
  title: 'Built With Science uses the latest in science to help you get results fast. How familiar are you with the latest research in fitness and nutrition?',
  type: 'radiogroup',
  choices: [
    {
      value: ScienceFamiliarity.Very,
      text: 'Very familiar, I stay on top of the latest research and have a lot of experience with analyzing studies',
    },
    {
      value: ScienceFamiliarity.Somewhat,
      text: `I'm familiar with a lot of the research but I have a hard time putting it all together into a plan specific to me`,
    },
    {
      value: ScienceFamiliarity.Attempted,
      text: `I try to gather science-based fitness information online but it's hard to know who I can trust`,
    },
    {
      value: ScienceFamiliarity.NotAtAll,
      text: `Not at all, I think I've been following a lot of "bro-science"`,
    },
  ],
  isRequired: true,
};

export {
  ScienceFamiliarity,
  scienceQuestion,
};