import { Goals } from "./goals";
import { WeightUnit } from "./weight";

enum MuscleGain {
  Five = 5,
  Ten = 10,
  Fifteen = 15,
  Twenty = 20,
  Max = 999,
  NotSure = -1,
}

const muscleGainQuestion = {
  visibleIf: `{goals} = "${Goals.BuildMuscle}"`,
  title: "How many lbs of muscle would you like to gain?",
  name: "muscleGain",
  type: "radiogroup",
  choices: [
    {
      value: MuscleGain.Five,
      text: `{muscleGainFiveText}`,
    },
    {
      value: MuscleGain.Ten,
      text: `{muscleGainTenText}`
    },
    {
      value: MuscleGain.Fifteen,
      text: `{muscleGainFifteenText}`,
    },
    {
      value: MuscleGain.Twenty,
      text: `{muscleGainTwentyText}`,
    },
    {
      value: MuscleGain.Max,
      text: "As much as possible",
    },
    {
      value: MuscleGain.NotSure,
      text: "I'm not sure, whatever is recommended",
    },
  ],
  isRequired: true,
};

const muscleGainTextExpressions = [
  {
    name: "muscleGainFiveText",
    expression: `iif({weightUnit} = '${WeightUnit.Kilograms}', '2.5 kg', '5 lbs')`,
  },
  {
    name: "muscleGainTenText",
    expression: `iif({weightUnit} = '${WeightUnit.Kilograms}', '5 kg', '10 lbs')`,
  },
  {
    name: "muscleGainFifteenText",
    expression: `iif({weightUnit} = '${WeightUnit.Kilograms}', '7.5 kg', '15 lbs')`,
  },
  {
    name: "muscleGainTwentyText",
    expression: `iif({weightUnit} = '${WeightUnit.Kilograms}', '10 kg', '20 lbs')`,
  },
]

export {
  MuscleGain,
  muscleGainQuestion,
  muscleGainTextExpressions,
};
