enum Age {
  Teens = "Teens",
  Twenties = "Twenties",
  Thirties = "Thirties",
  Fourties = "Fourties",
  OverFifty = "Over Fifty",
}


const ageQuestion = {
  name: "age",
  description: `We've helped thousands of {genderNoun} of all ages achieve their fitness goals. Metabolism tends to drop with age. But there's ways around that.`,
  descriptionLocation: "underTitle",
  title: `What is your age?`,
  type: "radiogroup",
  choices: Object.values(Age),
  isRequired: true,
};

export { Age, ageQuestion };
