import { useState } from 'react';
import styled from 'styled-components';
import { Card } from '../common-ui/Card';
import { landingPageListFont } from '../common-ui/styles';
import { Text } from '../common-ui/Text';

const AccordionWrapper = styled(Card)`
  width: 100%;
  padding: 0;
  overflow: hidden;
  border-color: #1e1e1e;
  border-style: solid;
  border-width: 1px;
  margin: 0;
`;

const AccordionHeader = styled.div`
  display: flex;
  gap: 20px;
  & > span:first-child {
    color: #406EDF;
  }

  ${landingPageListFont}
  align-items: center;
  padding: 10px 15px;
  border-color: #406EDF;
  color: #000000;
  cursor: pointer;
  user-select: none;
`;

const AccordionContent = styled(Card) <{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  transition: max-height 0.05s ease-in-out;
  padding: ${({ isOpen }) => (isOpen ? '15px' : '0 15px')};
  transition: padding 0.05s ease-in-out;
  overflow: hidden;
  margin-bottom: 0;
`;

const AnswerText = styled(Text)`
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`;

const AccordionItem = ({ question, answer }: { question: string; answer: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={toggleAccordion}>
        <span>{isOpen ? '-' : '+'}</span>
        <span>{question}</span>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>
        <AnswerText>{answer}</AnswerText>
      </AccordionContent>
    </AccordionWrapper>
  );
};

const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FAQ_LIST = [
  {
    question: 'Does the program come with meals?',
    answer: `Yes, you'll have access to a database of over 200+ high protein, quick, and easy meals. We've also created 90 done-for-you nutrition plans, including Vegan options, so you're never left guessing what or how much you need to eat to achieve your goals.`,
  },
  {
    question: 'What equipment do I need?',
    answer: `We've designed the program to work regardless of what equipment you may or may not have. Whether you have access to a full out gym, just barbells and dumbbells, just a set of bands, or only your bodyweight, we have workouts that will cater to you.`,
  },
  {
    question: 'I have past injuries holding me back, what if I can\'t do some of the exercises?',
    answer: `No problem at all! There's no exercise that you're “forced” to do in the program. We provide you with plenty of joint-friendly alternatives in the event that you have a past injury. We'll also show you how to improve your injury - many of our members have completely healed their past injuries and pains by following our protocols.`,
  },
  {
    question: 'How long will it take for me to see results?',
    answer: `Our clients typically see results quickly. You can expect to significantly change your physique within 12 weeks of consistently using the program, and then it gets even better the longer you stick with it. Just imagine: 3 months from now you could look and feel drastically better than you do right now!`,
  },
  {
    question: "I'm really busy. will I be able to fit the workouts into my schedule?",
    answer: "We believe in working smarter, not harder. Our scientifically-proven workouts do more for you in less time, so you don't need to kill yourself at the gym. We also have included 30-minute time-saver workouts to choose from if you're ever short on time."
  },
  {
    question: "Sometimes I can't make it to the gym, will I be able to work out at home?",
    answer: "Yes. As a bonus, you'll gain access to a full home workout routine that is just as effective as the gym workout routine."
  },
  {
    question: "What if I get invited to a dinner party, want to eat out or have a delicious home cooked meal?",
    answer: "We've got you covered! Inside the program you'll get our guide to eating out and eating in. We don't believe in restricting you, so we'll show you how to accommodate dinners with your family, friends or in restaurants."
  },
];

const FAQ = () => {

  return (
    <Accordion>
      {FAQ_LIST.map((faq, index) => (
        <AccordionItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Accordion>
  );
};

export { FAQ };

