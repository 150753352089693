enum NumberWorkouts {
  Three = '3',
  Four = '4',
  Five = '5',
  AsManyAsPossible = 'As many as possible',
}

const numberWorkoutsQuestion = {
  name: 'numberWorkouts',
  title: 'For the best results, we recommend at least 3 workouts per week. How many days per week are you able to workout?',
  description: `More isn't always better. Choose what you'll be most consistent with.`,
  descriptionLocation: 'underTitle',
  type: 'radiogroup',
  choices: [
    {
      value: NumberWorkouts.Three,
      text: '3 days per week',
    },
    {
      value: NumberWorkouts.Four,
      text: '4 days per week',
    },
    {
      value: NumberWorkouts.Five,
      text: '5 days per week',
    },
    {
      value: NumberWorkouts.AsManyAsPossible,
      text: 'As many days as will get me the best results',
    },
  ],
  isRequired: true,
};

export {
  NumberWorkouts,
  numberWorkoutsQuestion,
}