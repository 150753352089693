import { faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { Chart, ChartData, CustomTooltipProps, TitleStyle, TooltipContainer, TooltipText } from "./Chart";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { Guard } from "../utils";
import _ from "lodash";
import { WeightUnit, getWeightKg } from "../quiz/weight";

const createCustomTooltip = ({ active, payload }: CustomTooltipProps): JSX.Element => {
  if (active && payload && payload.length) {
    const displayWeight = payload[0].value as number;
    const date = DateTime.fromMillis(payload[0].payload.date);
    const showYear = date.year !== DateTime.local().year;
    return (
      <TooltipContainer>
        <TooltipText>{`Muscle Gained by ${date.toFormat(`d MMM${showYear ? ' yyyy' : ''}`)}:  ${displayWeight.toPrecision(2)} lbs`}</TooltipText>
      </TooltipContainer>
    );
  } else {
    return <></>;
  }
};

type MuscleGainData = ChartData & {
  muscleGainedLbs: number;
};

const isMuscleGainData: Guard<MuscleGainData> = (data: unknown): data is MuscleGainData => {
  return (data as MuscleGainData).muscleGainedLbs !== undefined;
};

type MuscleGainChartProps = {
  data: MuscleGainData[];
  weightUnit: WeightUnit;
  showTitle?: TitleStyle;
}

const MuscleGainChart = (props: MuscleGainChartProps) => {
  const { data, showTitle, weightUnit } = props;
  const hideYear = useMemo(() => data[data.length - 1].date.year === DateTime.local().year, [data]);

  if (data != null && data.length > 0) {
    const predictedDate = _.last(data)!.date.toFormat(`d MMM ${hideYear ? '' : 'yyyy'}`);
    const titlePrefix = TitleStyle.Text === showTitle ? 'We predict you\'ll gain' : `YOUR MUSCLE GAIN`;
    const totalGained = weightUnit === WeightUnit.Pounds ? _.last(data)!.muscleGainedLbs : getWeightKg(_.last(data)!.muscleGainedLbs, WeightUnit.Pounds);
    const titlePrediction = `${totalGained} ${weightUnit} of muscle by ${predictedDate}`;
    const finalMarker = `Muscle Gained: ${totalGained} ${weightUnit}`;

    return <Chart
      data={data.map((d) => ({ ...d, muscleGained: weightUnit === WeightUnit.Kilograms ? getWeightKg(d.muscleGainedLbs, WeightUnit.Pounds) : d.muscleGainedLbs }))}
      dataKey="muscleGained"
      showTitle={showTitle}
      icon={faDumbbell}
      CustomTooltip={createCustomTooltip}
      titlePrefix={titlePrefix}
      titlePrediction={titlePrediction}
      finalMarker={finalMarker}
      caret={'▲'}
      markerLocation="top"
      yAxisLabel={`Muscle (${weightUnit})`} />
  } else {
    return <></>;
  }
};

export {
  MuscleGainChart,
  isMuscleGainData,
};

export type { MuscleGainChartProps, MuscleGainData };
