enum Equipment {
  FullCommercialGym = 'FullCommercialGym',
  FullHomeGym = 'FullHomeGym',
  DumbellsAndBands = 'DumbellsAndBands',
  Bodyweight = 'Bodyweight',
}

const equipmentQuestion = {
  name: 'equipment',
  title: 'What equipment do you have access to?',
  type: 'radiogroup',
  choices: [
    {
      value: Equipment.FullCommercialGym,
      text: `I have access to a full commercial gym`,
    },
    {
      value: Equipment.FullHomeGym,
      text: `I have a full home gym`,
    },
    {
      value: Equipment.DumbellsAndBands,
      text: `I have dumbbells and resistance bands`,
    },
    {
      value: Equipment.Bodyweight,
      text: `I only have access to my bodyweight`,
    },
  ],
  // showOtherItem: true,
  isRequired: true,
};

export {
  Equipment,
  equipmentQuestion,
}