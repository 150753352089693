import { SelfDescription } from "./describeSelf";

enum WhyConsistency {
  Time = 'Time',
  Motivation = 'Motivation',
  Injuries = 'Injuries',
  ProgramHopping  = 'ProgramHopping',
};

const whyConsistencyQuestion = {
  visibleIf: `{describeSelf} == "${SelfDescription.StruggleConsistency}"`,
  name: 'whyConsistency',
  title: 'What is affecting your consistency?',
  type: 'radiogroup',
  choices: [
    {
      value: WhyConsistency.Time,
      text: 'Time: Work, school, life commitments',
    }, {
      value: WhyConsistency.Motivation,
      text: 'Motivation/Plateaus: Not seeing much progress and losing motivation',
    }, {
      value: WhyConsistency.Injuries,
      text: 'Injuries: Limit what I can do',
    }, {
      value: WhyConsistency.ProgramHopping,
      text: 'Program Hopping: I switch programs frequently',
    }
  ],
  isRequired: true,
  // showOtherItem: true,
};

export {
  WhyConsistency,
  whyConsistencyQuestion,
}