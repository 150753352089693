import { FunctionFactory } from 'survey-core';
import { Gender } from '../quiz/gender';
import { getTestimonialByName } from './testimonials';

const muscleFocusSplashHtml = `
<div class="quote-container">
  <img src="{muscleFocusImgUrl}" alt="Testimonial from a user like you!" />
  <div class="quote">
    {muscleFocusQuote}
  </div>
</div>`;

const muscleFocusQuoteExpression = {
  name: "muscleFocusQuote",
  expression: "renderMuscleFocusQuote({gender})",
};

const muscleFocusImgUrlExpression = {
  name: "muscleFocusImgUrl",
  expression: "renderMuscleFocusImgUrl({gender})",
}

function renderMuscleFocusQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Gender) {
    case Gender.Male: {
      return `${getTestimonialByName('Patrick R.').testimonial} <div><strong>- Patrick R.</strong></div>`;
    }
    case Gender.Female: {
      return `${getTestimonialByName('Tahnee S.').testimonial} <div><strong>- Tahnee S.</strong></div>`;
    }
  }
}

function renderMuscleFocusImgUrl(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Gender) {
    case Gender.Male: {
      return getTestimonialByName('Patrick R.').image;
    }
    case Gender.Female: {
      return getTestimonialByName('Tahnee S.').image;
    }
  }
}

FunctionFactory.Instance.register("renderMuscleFocusQuote", renderMuscleFocusQuote);
FunctionFactory.Instance.register("renderMuscleFocusImgUrl", renderMuscleFocusImgUrl);

const muscleFocusSplashElement = {
  name: 'muscle-focus-splash',
  type: 'html',
  html: muscleFocusSplashHtml,
};

export {
  muscleFocusSplashElement,
  muscleFocusQuoteExpression,
  muscleFocusImgUrlExpression,
}
