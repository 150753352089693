import { FunctionFactory } from 'survey-core';
import { Equipment } from '../quiz/equipment';
import { getMotivationalByName } from './testimonials';
import { Gender } from '../quiz/gender';

const equipmentSplashHtml = `
<div class="quote-container"> 
  <div class="quote">
    {equipmentQuote}
  </div>
  <img src="{equipmentImgUrl}" alt="What you can achieve at home" />
  <div>{equipmentTestimonialsDisplayName} - Verified Built With Science User</div>
</div>`;

const equipmentSplashExpression = {
  name: "equipmentQuote",
  expression: "renderEquipmentQuote({equipment})",
};

function renderEquipmentQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Equipment) {
    case Equipment.FullHomeGym:
    case Equipment.DumbellsAndBands:
      return `Great! That's more than enough. We use science to get you incredible results regardless of what equipment you do or don't have.`;
    case Equipment.FullCommercialGym:
      return `Great! If you are ever limited on equipment (e.g. traveling) or want to consider working out at home, we'll give you alternative workouts that are just effective.`;
    default:
      return `Great! We'll take that into consideration. We use science to get you incredible results regardless of what equipment you do or don't have.`;
  }
}

FunctionFactory.Instance.register("renderEquipmentQuote", renderEquipmentQuote);

const equipmentSplashElement = {
  name: 'equipment-splash',
  type: 'html',
  html: equipmentSplashHtml,
}

function renderEquipmentImgUrl(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Gender) {
    case Gender.Female:
      return getMotivationalByName('Ligia').image;
    case Gender.Male:
      return getMotivationalByName('Jesse').image;
  }
}

const equipmentSplashImgUrlExpression = {
  name: "equipmentImgUrl",
  expression: "renderEquipmentImgUrl({gender})",
};

FunctionFactory.Instance.register("renderEquipmentImgUrl", renderEquipmentImgUrl);

const equipmentTestimonialsDisplayNameExpression = {
  name: "equipmentTestimonialsDisplayName",
  expression: "renderEquipmentTestimonialsDisplayName({gender})",
};

function renderEquipmentTestimonialsDisplayName(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Gender) {
    case Gender.Female:
      return getMotivationalByName('Ligia').displayName;
    case Gender.Male:
      return getMotivationalByName('Jesse').displayName;
  }
}

FunctionFactory.Instance.register("renderEquipmentTestimonialsDisplayName", renderEquipmentTestimonialsDisplayName);


export {
  equipmentSplashElement,
  equipmentSplashExpression,
  equipmentSplashImgUrlExpression,
  equipmentTestimonialsDisplayNameExpression,
}