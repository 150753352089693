import { useEffect, useMemo } from "react";
import { MonthlyYearlyProgramLevelProps } from "./MonthlyYearlyProgramLevel.types";
import { sendViewItemEvent } from "../../services/googleTagManager";
import {
  BonusProgramFeature,
  CollapsingDetails,
  Headline,
  ProgramFeature,
  ProgramImage,
  ProgramLevelCard,
  PurchaseButtonCaption,
  Whitespace,
  extractFileName,
} from "./programLevelComponents";

import { PriceBlockYearly } from "./PriceBlockYearly";
import ProgramBuyButton from "./ProgramBuyButton";
import Markdown from "react-markdown";
import styled from "styled-components";

const ProgramTitleSpan =styled.span`
font-size:50px;
@media screen and (min-width: 800px) and (max-width: 1200px){
  font-size:34px;
}
`;
const ProgramTitleSpace =styled.span`
font-size:50px;
@media screen and (min-width: 800px) and (max-width: 1200px){
  font-size:34px;
}
`;


const StrongBonusText = styled.strong`
  font-style: bold;
  color: #1e1e1e;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left:-20px;
  font-size:22px;
`;
const BulletPointsEmpty = styled.p`
  height:30px;
`;
const LimitedTimeText = styled.p`
  font-style: italic;
  color: #98a1a8;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size:14px;
`;
const SavingsContainer = styled.span`
font-weight: 900;
color: white;
margin-left: 10px;
font-size: 25px;
padding: 5px 5px;
background: red;
border-radius: 15px;
@media screen and (min-width: 800px) and (max-width: 1200px){
  font-size:16px;
}
`;



const ProgramTitleMonthlyYearly = styled.h3`
  font-family: "Oswald", sans-serif;
  font-size: 40px;
  color: #10181f;
  margin-bottom: 5px;
  margin-top: 5px;
  @media screen and (min-width: 800px) and (max-width: 1200px){
    font-size:30px;
  }
`;




const MonthlyYearlyProgramLevel = (props: MonthlyYearlyProgramLevelProps) => {
  const {
    price,
    productId,
    listName,
    name,
    image,
    mobileOrder,
    headline,
    title,
    buyLink,
    guaranteeDurationDays,
    featureHeader,
    features,
  } = props;
  const hasDiscount = price.original > price.current;
  const is_annual = price.is_annual;
  const itemEventData = useMemo(
    () => ({
      itemListName: listName,
      items: [
        {
          itemId: productId,
          originalPrice: price.original,
          price: price.current,
          itemName: name,
        },
      ],
    }),
    [price, productId, name, listName]
  );

  useEffect(() => {
    sendViewItemEvent(itemEventData);
  });

  return (
    <ProgramLevelCard mobileOrder={mobileOrder}>
      <ProgramImage src={image} alt={extractFileName(image)} />
      {headline && (
        <Headline id={getMonthlyYearlyProgramLevelCardId(title)} {...headline}>
          {headline.title}
         
        </Headline>
      )}
      <ProgramTitleMonthlyYearly>BWS{hasDiscount? <ProgramTitleSpan>+</ProgramTitleSpan> : <ProgramTitleSpace></ProgramTitleSpace>} {title}
      {is_annual ? <SavingsContainer>
              Save 47%            
            </SavingsContainer>
            : null}
      </ProgramTitleMonthlyYearly> 
      
      <PriceBlockYearly 
        current={price.current}
        original={price.original}
        hasLimitedSupply={hasDiscount}
        is_annual={is_annual}
      />
      
      <ProgramBuyButton
        buyLink={buyLink}
        itemEventData={itemEventData}
        text={hasDiscount? "START FREE TRIAL" : "JOIN TODAY"}
      />
      
       {hasDiscount ?   <LimitedTimeText>14 Day Free Trial. Cancel Anytime. <br/>
             </LimitedTimeText> : <Whitespace> <br /></Whitespace> } 
      <PurchaseButtonCaption>
      {featureHeader ? featureHeader : guaranteeDurationDays + " day money-back guarantee" }
      </PurchaseButtonCaption>
      <CollapsingDetails>
        <ul>
      
          {features.intro.map((item, i) => (
            <ProgramFeature key={i} className={` ${i < 1 ? "active" : ""}`} >
              <Markdown>{item}</Markdown>
            </ProgramFeature>
          ))}
          {features.intro.length >0 ? (
            <>
            </>
          ):(
            <BulletPointsEmpty>
            
            </BulletPointsEmpty>
            
           
          
          )}
           {features.basic.map((item, i) => (
            <ProgramFeature key={i}  >
              <Markdown>{item}</Markdown>
            </ProgramFeature>
          ))}
          {features.bonus.map((item, i) => (
            <BonusProgramFeature key={i}>
              <Markdown>{item}</Markdown>
            </BonusProgramFeature>
          ))}
          <StrongBonusText>{ features.extra.length ?  "Bonus:": "" }</StrongBonusText>
          {features.extra.map((item, i) => (
            <BonusProgramFeature key={i}>
              <Markdown>{item}</Markdown>
            </BonusProgramFeature>
          ))}
        </ul>
      </CollapsingDetails>
    </ProgramLevelCard>
  );
};

const getMonthlyYearlyProgramLevelCardId = (title: string) =>
  `fourmonth-program-level-${title}`;

export { MonthlyYearlyProgramLevel, getMonthlyYearlyProgramLevelCardId };
