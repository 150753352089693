import { FunctionFactory } from 'survey-core';

const injuriesSplashHtml = `
<div class="quote-container"> 
  <img src="https://cdn.builtwithscience.com/images/splash/injuries{gender}.jpg" alt="Testimonial from a user like you!" />
  <div class="quote">
    {injuriesQuote}
  </div>
</div>`;

const injuriesSplashExpression = {
  name: "injuriesQuote",
  expression: "renderInjuriesQuote({injuries})",
};

function renderInjuriesQuote(params: any[]): string {
  if (params === undefined || params === null || params.length === 0) return "";
  if (params[0]?.[0] === "none") {
    return `That's awesome! We'll help you keep it that way by using smart workouts designed to maximize gains while keeping your joints healthy.`;
  } 
  return `Injuries are common. We'll show you how to work around them so they don't hold you back from getting results. We'll also show you a routine that can help recover any injuries you're dealing with.`;
}

FunctionFactory.Instance.register("renderInjuriesQuote", renderInjuriesQuote);

const injuriesSplashElement = {
  name: 'injuries-splash',
  type: 'html',
  html: injuriesSplashHtml,
}

export {
  injuriesSplashElement,
  injuriesSplashExpression,
}
