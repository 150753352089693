import { ratingQuestionTemplate } from "./utils";

const dissatisfactionQuestion = {
  ...ratingQuestionTemplate,
  name: 'dissatisfaction',
  title: `How strongly do you relate to the below statement?

<span class="quote-container">"I feel dissatisfied with where I'm at and I know I'm capable of more. I'm ready to change and commit to a plan, but I just need something I can trust will work."</span>`,
};

export {
  dissatisfactionQuestion,
}