function isNullOrUndefined(
  value: any | null | undefined,
): value is null | undefined {
  return value === null || value === undefined;
}

const ratingQuestionTemplate = {
  isRequired: true,
  type: 'rating',
  displayMode: 'buttons',
  description: '<span class="rating-question-left">Strongly Disagree</span><span class="rating-question-right">Strongly Agree</span>',
  rateMin: 1,
  rateMax: 5,
  descriptionLocation: 'underInput',
  minRateDescription: 'Strongly Disagree',
  maxRateDescription: 'Strongly Agree',
}

export {
  isNullOrUndefined,
  ratingQuestionTemplate,
}