import { FunctionFactory } from 'survey-core';
import { Goals } from './goals';
import { ActivityLevel } from './activity';
import { BodyFat } from './bodyfat';
import { calculateCalorieTarget } from '../features/calculations';
import { Experience } from './experience';
import { Gender } from './gender';
import { WeightUnit, getWeightPounds } from './weight';

enum CalorieTracking {
  NoIdea = 'NoIdea',
  EatingMore = 'EatingMore',
  EatingLess = 'EatingLess',
  PrettyClose = 'PrettyClose',
}

const caloriesQuestion = {
  name: 'calories',
  title: `To {goalsText}, {goalCaloriesText} calories than you're burning every day.
  
  We estimate you should eat around <span class="number-text">{caloriesText}</span> per day.
  
  How close are you to this?`,
  type: 'radiogroup',
  choices: [
    {
      value: CalorieTracking.NoIdea,
      text: `I have no idea, I've never kept track`,
    },
    {
      value: CalorieTracking.EatingMore,
      text: `I'm definitely eating more than that`,
    },
    {
      value: CalorieTracking.EatingLess,
      text: `I'm definitely eating less than that`,
    },
    {
      value: CalorieTracking.PrettyClose,
      text: `I'm pretty close to that target everyday`,
    },
  ],
  isRequired: true,
};

const goalCaloriesTextExpression = {
  name: 'goalCaloriesText',
  expression: "renderGoalCaloriesText({goals})",
};

const caloriesTextExpression = {
  name: 'caloriesText',
  expression: "renderCaloriesText({weightUnit}, {weight}, {bodyFat}, {activity}, {experience}, {goals}, {gender}, {weight})",
};

function renderGoalCaloriesText(params: any[]): string {
  const goals = params[0] as Goals;
  switch (goals) {
    case Goals.LoseFat:
      return `you need to eat less`;
    case Goals.BuildMuscle:
      return `you need to eat slightly more`;
    case Goals.BuildMuscleAndLoseFat:
      return `you need to eat slightly less`;
  }
}

// round to the nearest 50
function roundToNearest50(num: number): number {
  return Math.round(num / 50) * 50;
}

function renderCaloriesText(params: any[]): string {
  if (params === undefined || params === null) return '';
  const weightUnit = params[0] as WeightUnit;
  const weightRaw = params[1]?.weightRaw as number;
  const bodyFat = params[2] as BodyFat;
  const activityLevel = params[3] as ActivityLevel;
  const experience = params[4] as Experience;
  const goal = params[5] as Goals;
  const gender = params[6] as Gender;
  const weightPounds = getWeightPounds(weightRaw, weightUnit);
  const target = calculateCalorieTarget(experience, goal, bodyFat, gender, weightPounds, activityLevel);
  const roundedTarget = roundToNearest50(target);
  let targets = [roundedTarget - 50, roundedTarget + 50];
  
  // Ensure the minimum limit after considering the window
  targets[0] = Math.max(gender === Gender.Male ? 1500 : 1200, targets[0]);
  targets[1] = Math.max(targets[0] + 100, targets[1]);
  return `${targets[0]} to ${targets[1]} calories`
}

FunctionFactory.Instance.register('renderGoalCaloriesText', renderGoalCaloriesText);
FunctionFactory.Instance.register('renderCaloriesText', renderCaloriesText);

export {
  CalorieTracking,
  caloriesQuestion,
  goalCaloriesTextExpression,
  caloriesTextExpression,
  renderCaloriesText,
}