import { FunctionFactory } from 'survey-core';
import { ScienceFamiliarity } from '../quiz/scienceFamiliarity';

const scienceSplashHtml = `
<div class="quote-container"> 
  <div class="quote">
    {scienceQuote}
  </div>
</div>`;

const scienceSplashExpression = {
  name: "scienceQuote",
  expression: "renderScienceQuote({science})",
};

function renderScienceQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as ScienceFamiliarity) {
    case ScienceFamiliarity.Very:
      return `Great! You're one step ahead and are already familiar with the power of applying science to fitness and nutrition. The next few questions will help us learn more about you.`;
    case ScienceFamiliarity.Somewhat:
      return `We don't blame you. It's not easy to take complex information and create a simple plan that takes YOU into account. Let us take care of the guesswork for you. The next few questions will help us learn more about you.`;
    case ScienceFamiliarity.Attempted:
      return `We don't blame you. It's not easy to know who or what to trust. Let us take care of the guesswork for you. The next few questions will help us learn more about you.`;
    case ScienceFamiliarity.NotAtAll:
      return "Thanks for sharing that with us. The good news is that by switching to a science-based approach, you'll start seeing results really fast. The next few questions will help us learn more about you.";
  }
}

FunctionFactory.Instance.register("renderScienceQuote", renderScienceQuote);

const scienceSplashElement = {
  name: 'science-splash',
  type: 'html',
  html: scienceSplashHtml,
}

export {
  scienceSplashElement,
  scienceSplashExpression,
}