import { ratingQuestionTemplate } from "./utils";

const consistencyQuestion = {
  ...ratingQuestionTemplate,
  name: 'consistency',
  title: `How strongly do you relate to the below statement?

  <span class="quote-container">"I struggle with staying consistent. I need some outside motivation or accountability. I make a bit of progress but then lose it all as soon as I stop being consistent."</span>`,
};

export {
  consistencyQuestion,
}
