import { Guard, isNumber, isType } from "../utils";
import { Goals } from "./goals";
import { FunctionFactory } from "survey-core";

function validateGoalWeight(params: any[]): boolean {
  if (params === undefined || params === null) return false;
  const weightRaw = params[0]?.weightRaw as number | undefined;
  const goalWeight = params[1]?.goalWeightRaw as number | undefined;
  if (goalWeight != null && weightRaw != null && goalWeight < weightRaw) {
    return true;
  }
  return false;
}

const validateGoalWeightExpression = {
  name: 'validateGoalWeight',
  expression: "validateGoalWeight({weight}, {goalWeight})",
};

FunctionFactory.Instance.register('validateGoalWeight', validateGoalWeight);

const goalWeightQuestion = {
  name: "goalWeight",
  visibleIf: `{goals} = "${Goals.LoseFat}"`,
  title: "What is your ideal weight that you want to reach?",
  descriptionLocation: "underTitle",
  type: "multipletext",
  inputType: "number",
  items: [
    {
      name: "goalWeightRaw",
      title: "{weightUnit}",
      isRequired: true,
      inputType: 'number',
      validators: [{
        type: "numeric",
        minValue: 0,
        text: "Please enter a valid weight.",
      }, {
        type: "expression",
        expression: "validateGoalWeight({weight}, {goalWeight})",
        text: "Your goal weight should be less than your current weight.",
      }],
    }
  ],
  isRequired: true,
};

type GoalWeight = {
  goalWeightRaw: number;
};

const isGoalWeight: Guard<GoalWeight> = isType<GoalWeight>({ goalWeightRaw: isNumber });

export {
  isGoalWeight,
  goalWeightQuestion,
  validateGoalWeightExpression,
}