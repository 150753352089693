
const almostThereSplashHtml = `
<div class="quote-container"> 
  <div class="quote">
    You're almost there! Just a few more questions about your nutrition then your plan will be ready!
  </div>
</div>`;

const almostThereSplashElement = {
  name: 'almost-there-splash',
  type: 'html',
  html: almostThereSplashHtml,
}

export {
  almostThereSplashElement,
}