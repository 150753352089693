import Statsig from "statsig-js";

const STATSIG_CLIENT_KEY = process.env.REACT_APP_STATSIG_CLIENT_KEY || "";

const STRIPE_LINK_GATE = {
  name: "four_month_stripe_links",
} as const;

const getVersionNumber = () => {
  const experiment = STRIPE_LINK_GATE;
  const config = Statsig.checkGateWithExposureLoggingDisabled(experiment.name);
  return config ? "v 2.5.1" : "v 2.5.0";
};

const checkStripeGate = () => {
  return Statsig.checkGateWithExposureLoggingDisabled(STRIPE_LINK_GATE.name);
};

export {
  STATSIG_CLIENT_KEY,
  STRIPE_LINK_GATE,
  getVersionNumber,
  checkStripeGate,
};
