import { FunctionFactory } from 'survey-core';
import { SelfDescription } from '../quiz/describeSelf';

const describeSelfSplashHtml = `
<div class="quote-container"> 
  <div class="quote">
    {describeSelfQuote}
  </div>
</div>`;

const describeSelfSplashExpression = {
  name: "describeSelfQuote",
  expression: "renderDescribeSelfSplash({describeSelf})",
};

function renderDescribeSelfSplash(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as SelfDescription) {
    case SelfDescription.DietAndTrainingNeedWork:
    case SelfDescription.SlowProgress:
      return "You're probably doing better than you think! We'll help you identify what's working and how to stick with it!";
    case SelfDescription.StruggleConsistency:
    default:
      return "";
  }
}

FunctionFactory.Instance.register("renderDescribeSelfSplash", renderDescribeSelfSplash);

const describeSelfSplashElement = {
  visibleIf: `{describeSelf} != "${SelfDescription.StruggleConsistency}"`,
  name: 'describe-self-splash',
  type: 'html',
  html: describeSelfSplashHtml,
}

export {
  describeSelfSplashElement,
  describeSelfSplashExpression,
}
