import { FunctionFactory } from 'survey-core';
import { WhyConsistency } from "../quiz/whyConsistency";
import { SelfDescription } from '../quiz/describeSelf';

const whyConsistencySplashHtml = `
<div class="quote-container">
  <div class="quote">
    <div><span class="bold">Staying consistent to a plan can be hard.</span> {whyConsistencySplashQuote}</div>
  </div>
</div>`;

const whyConsistencySplashExpression = {
  name: "whyConsistencySplashQuote",
  expression: "renderWhyConsistencySplashQuote({whyConsistency})",
};

function renderWhyConsistencySplashQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  const whyConsistency = params[0] as WhyConsistency;
  if (whyConsistency === WhyConsistency.Time) {
    return `You don't need to sacrifice everything just to get results. As long as you're consistent 70-80% of the time, you can still make great progress.`;
  }
  return "";
}

FunctionFactory.Instance.register("renderWhyConsistencySplashQuote", renderWhyConsistencySplashQuote);

const whyConsistencySplashElement = {
  visibleIf: `{describeSelf} == "${SelfDescription.StruggleConsistency}" and {whyConsistency} == "${WhyConsistency.Time}"`,
  name: 'why-consistency-splash',
  type: 'html',
  html: whyConsistencySplashHtml,
};

export {
  whyConsistencySplashElement,
  whyConsistencySplashExpression,
}