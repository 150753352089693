
const contrastColor = (backgroundColor: string) => {
  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  };

  const rgb = hexToRgb(backgroundColor);
  if (!rgb) return "#FFFFFF";

  const luminance = (r: number, g: number, b: number) => {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
  };

  const bgColorLuminance = luminance(rgb.r, rgb.g, rgb.b);
  const blackLuminance = luminance(0, 0, 0);
  const whiteLuminance = luminance(255, 255, 255);
  const blackContrast = (bgColorLuminance + 0.05) / (blackLuminance + 0.05);
  const whiteContrast = (whiteLuminance + 0.05) / (bgColorLuminance + 0.05);

  return blackContrast > whiteContrast ? "#000000" : "#FFFFFF";
};

export {
  contrastColor,
}