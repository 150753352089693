import { FunctionFactory } from "survey-core";
import { Goals } from "./goals";
import { Gender } from "./gender";
import { calculateProteinTarget } from "../features/calculations";
import { BodyFat } from "./bodyfat";
import { WeightUnit, getWeightPounds } from "./weight";

enum ProteinTracking {
  NoIdea = 'NoIdea',
  EatingMore = 'EatingMore',
  EatingLess = 'EatingLess',
  PrettyClose = 'PrettyClose',
}

const proteinQuestion = {
  name: 'protein',
  title: `{goalProteinText} it's important you eat enough protein.
  
  We estimate you should eat at least <span class="number-text">{proteinText} grams of protein</span> per day. 
  
  How close are you to this target?`,
  type: 'radiogroup',
  choices: [
    {
      value: ProteinTracking.NoIdea,
      text: `I have no idea, I've never kept track`,
    },
    {
      value: ProteinTracking.EatingMore,
      text: `I'm definitely eating more than that`,
    },
    {
      value: ProteinTracking.EatingLess,
      text: `I'm definitely eating less than that`,
    },
    {
      value: ProteinTracking.PrettyClose,
      text: `I'm pretty close to that target everyday`,
    },
  ],
  isRequired: true,
};

const goalProteinTextExpression = {
  name: 'goalProteinText',
  expression: "renderGoalProteinText({goals})",
};

const proteinTextExpression = {
  name: 'proteinText',
  expression: "renderProteinText({gender}, {weightUnit}, {weight}, {bodyFat})",
};

function renderGoalProteinText(params: any[]): string {
  const goals = params[0] as Goals;
  switch (goals) {
    case Goals.LoseFat:
      return `To build muscle and keep your metabolism high as you lose weight,`;
    case Goals.BuildMuscle:
      return `To maximize muscle growth,`;
    case Goals.BuildMuscleAndLoseFat:
      return `To maintain/build muscle as you lose fat,`;
  }
}

function renderProteinText(params: any[]): string {
  if (params === undefined || params === null) return "";
  const gender = params[0] as Gender;
  const weightUnit = params[1] as WeightUnit;
  const weightPounds = getWeightPounds(params[2]?.weightRaw as number, weightUnit);
  const bodyFat = params[3] as BodyFat;
  return calculateProteinTarget(gender, bodyFat, weightPounds).toFixed(0);
}

FunctionFactory.Instance.register('renderGoalProteinText', renderGoalProteinText);
FunctionFactory.Instance.register('renderProteinText', renderProteinText);

export {
  ProteinTracking,
  proteinQuestion,
  goalProteinTextExpression,
  proteinTextExpression,
  renderProteinText,
};

