import styled from 'styled-components';

type ProgressHeaderProps = {
  progress: number;
  title: string;
}

const Container = styled.div`
  max-width: 95%;
  width: calc(80 * var(--base-unit, 8px));
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.03em;
  font-size: calc(2 * var(--base-unit, 8px));
  line-height: calc(3 * var(--base-unit, 8px));
  text-transform: uppercase;
  margin-bottom: 16px;
  grid-column: 2;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #ddd;
  position: relative;
`;

const Indicator = styled.div<{ progress: number }>`
  height: 100%;
  background-color: #406EDF;
  width: ${props => props.progress}%;
`;

const Milestone = styled.div<{ value: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #fff;
  left: ${props => props.value}%;
`;

const ProgressHeader: React.FC<ProgressHeaderProps> = ({ progress, title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <ProgressBar>
        <Indicator progress={progress} />
        {[20, 40, 60, 80, 100].map((value, index) => (
          <Milestone key={index} value={value} />
        ))}
      </ProgressBar>
    </Container>
  );
};

export default ProgressHeader;
export type {
  ProgressHeaderProps,
}
