import { find } from "lodash";
import { Model, PageModel } from "survey-core";
import { isArrayOf, isBoolean, isNumber, isString } from "../utils";
import { SURVEY_JSON } from "../quiz.config";
import { isGoalWeight } from "../quiz/goalWeight";
import { HeightUnit } from "../quiz/height";
import { isWeight } from "../quiz/weight";
import { Demographics } from "../types";
import { Goals } from "../quiz/goals";
import { ChoicesQuestion, isChoicesQuestion } from "../quiz.types";
import { getVersionNumber, checkStripeGate } from "./experiments";
import { Statsig } from "statsig-react";
import * as amplitude from "@amplitude/analytics-browser";

enum FunnelVariation {
  General = "F0 - General",
  LoseWeight = "F1 - lose weight only",
  LoseFatBuildMuscle = "F2 - lose fat and build muscle",
  BuildMuscle = "F3 - build muscle only",
}

type QuizEventData = {
  event: string;
  funnel_variation: FunnelVariation;
  quizz_name: string;
  quizz_step: string;
  quizz_step_name: string;
  quizz_version: string; // versioning for the quiz. any time we add a new page, add a new question, change the question orders, etc this should change
};

type QuizEventStepData = {
  quizz_step_answer: string;
  quizz_step_answer_choises: string;
} & QuizEventData;

type DataEvent = {
  goal: Goals;
  quizz_step: string;
  quizz_step_name: string;
  quizz_step_answer: string;
  quizz_step_answer_choises: string;
};

type CommerceItem = {
  item_id: string;
  item_name: string;
  affiliation: string;
  coupon: string;
  discount?: number;
  index: number;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_list_id: string;
  item_list_name: string;
  item_variant: string;
  location_id: string;
  price: number;
  quantity: number;
};

type ViewItemListEventEcom = {
  item_list_id: string;
  item_list_name: string;
  items: CommerceItem[];
};

type ViewItemListEventData = {
  event: "view_item_list";
  ecommerce: ViewItemListEventEcom;
};

type ViewItemEventEcom = {
  currency: "USD";
  value: number;
  items: CommerceItem[];
};

type ViewItemEventData = {
  event: "view_item";
  ecommerce: ViewItemEventEcom;
};

type SelectItemEventEcom = {
  item_list_id: "related_products";
  item_list_name: "Related Products";
  items: CommerceItem[];
};

type SelectItemEventData = {
  event: "select_item";
  ecommerce: SelectItemEventEcom;
};

type AddToCartEventEcom = {
  currency: "USD";
  value: number;
  items: CommerceItem[];
};

type AddToCartEventData = {
  event: "add_to_cart";
  ecommerce: AddToCartEventEcom;
};

type EcomEvent =
  | ViewItemListEventData
  | ViewItemEventData
  | SelectItemEventData
  | AddToCartEventData;

type DataLayerInput = QuizEventData | QuizEventStepData | EcomEvent;

declare global {
  interface Window {
    dataLayer: (DataLayerInput | { ecommerce: null })[];
  }
}

const getFunnelVariation = (goal: Goals): FunnelVariation => {
  switch (goal) {
    case Goals.LoseFat:
      return FunnelVariation.LoseWeight;
    case Goals.BuildMuscle:
      return FunnelVariation.BuildMuscle;
    case Goals.BuildMuscleAndLoseFat:
      return FunnelVariation.LoseFatBuildMuscle;
    default:
      throw new Error(`Invalid goal: ${goal}`);
  }
};

function sendEvent(data: DataEvent) {
  const input: QuizEventStepData = {
    event: "quiz",
    funnel_variation: getFunnelVariation(data.goal),
    quizz_name: "New Onboarding Quiz",
    quizz_step: data.quizz_step,
    quizz_step_name: data.quizz_step_name,
    quizz_step_answer: data.quizz_step_answer,
    quizz_step_answer_choises: data.quizz_step_answer_choises,
    quizz_version: getVersionNumber(),
  };
  pushToDataLayer(input);
  const statsigData = quizToStatsig(input);
  Statsig.logEvent(
    statsigData.eventName,
    statsigData.value,
    statsigData.metadata
  );
  if (checkStripeGate()) {
    amplitude.track("quiz", {
      step: data.quizz_step,
      step_name: data.quizz_step_name,
      step_answer: data.quizz_step_answer,
      step_answer_choices: data.quizz_step_answer_choises,
      version: getVersionNumber(),
      funnel_variation: getFunnelVariation(data.goal),
    });
  }
}

function formatGTMAnswer(data: unknown, currentPageName: string): string {
  if (currentPageName === "name") {
    return "Name Provided";
  }
  const answer = (data as any)[currentPageName];
  if (currentPageName === "height") {
    const demographics = data as Demographics;
    return (
      `${demographics.heightRaw} ${demographics.heightUnit}` +
      (demographics.heightUnit === HeightUnit.Feet
        ? ` ${demographics.heightInches} in`
        : "")
    );
  }
  if (isString(answer) || isNumber(answer)) {
    return answer.toString();
  }
  if (isBoolean(answer)) {
    return String(answer);
  }
  if (isArrayOf(isString)(answer) || isArrayOf(isNumber)(answer)) {
    return answer.join(", ");
  }
  if (isWeight(answer)) {
    return answer.weightRaw.toString() + ` ${(data as any)["weightUnit"]}`;
  }
  if (isGoalWeight(answer)) {
    return answer.goalWeightRaw.toString();
  }
  return "";
}

const getChoices = (question: ChoicesQuestion): string => {
  return (
    question.choices
      ?.map((choice) =>
        typeof choice === "object" ? choice.value : String(choice)
      )
      .join(", ") || ""
  );
};

function quizToStatsig(data: QuizEventStepData): StatsigData {
  return {
    eventName: "quiz",
    value: String(data.quizz_step),
    metadata: {
      funnel_variation: data.funnel_variation,
      step_name: data.quizz_step_name,
      step_answer: String(data.quizz_step_answer),
      source: "quiz",
    },
  };
}

function sendChangesToGTM(
  sender: Model,
  currentPage: PageModel,
  pageIndex: number
) {
  const page = find(SURVEY_JSON.pages, (p) => p.name === currentPage.name);
  const hasChoices = page && isChoicesQuestion(page.elements[0]);

  const goals = sender.data.goals;
  const quizzStep = pageIndex;
  const quizzStepName = currentPage.name;
  const quizzStepAnswer = formatGTMAnswer(sender.data, currentPage.name);
  const quizzStepAnswerChoices = hasChoices ? getChoices(page.elements[0]) : "";

  sendEvent({
    goal: goals,
    quizz_step: String(quizzStep),
    quizz_step_name: quizzStepName,
    quizz_step_answer: quizzStepAnswer,
    quizz_step_answer_choises: quizzStepAnswerChoices,
  });
}

function sendPageViewEvent(
  goal: Goals,
  pageName: string,
  pageVariation: string,
  pageChoices: string[],
  pageIndex: number
) {
  sendEvent({
    goal,
    quizz_step: String(pageIndex),
    quizz_step_name: pageName,
    quizz_step_answer: pageVariation,
    quizz_step_answer_choises: pageChoices.join(", "),
  });
}

type ItemEvent = {
  itemListName: string; // e.g. Beginner shred program plus
  items: {
    itemId: string; // e.g. 8171029381231 or SKU
    originalPrice: number; // e.g. 97
    price: number;
    itemName: string; // e.g. "Beginner-SHRED 2.0 Main - Elite"
  }[];
};

function hyphenate(str: string) {
  return str.replace(/\s+/g, "-").toLowerCase();
}

function toCommerceItem(
  itemListName: string,
  item: ItemEvent["items"][0],
  index: number
): CommerceItem {
  return {
    item_id: item.itemId,
    item_name: item.itemName,
    affiliation: "",
    coupon: "",
    discount: item.originalPrice - item.price,
    index,
    item_brand: "BWS",
    item_category: itemListName,
    item_category2: "Bundle Pricing",
    item_list_id: hyphenate(itemListName),
    item_list_name: itemListName,
    item_variant: "",
    location_id: "",
    price: item.price,
    quantity: 1,
  };
}

function pushToDataLayer(data: DataLayerInput) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  // console.log(JSON.stringify(data));
  window.dataLayer.push(data);
}

function sendViewItemListEvent(data: ItemEvent) {
  const items = data.items.map((item, index) =>
    toCommerceItem(data.itemListName, item, index)
  );
  const eventData: ViewItemListEventEcom = {
    item_list_id: hyphenate(data.itemListName),
    item_list_name: data.itemListName,
    items,
  };
  pushToDataLayer({
    event: "view_item_list",
    ecommerce: eventData,
  });
  if (checkStripeGate()) {
    amplitude.track("view_item_list", {
      ...eventData,
    });
  }
}

function sendViewItemEvent(data: ItemEvent) {
  const items = data.items.map((item, index) =>
    toCommerceItem(data.itemListName, item, index)
  );
  const eventData: ViewItemEventEcom = {
    currency: "USD",
    value: items.reduce((acc, item) => acc + item.price, 0),
    items,
  };
  pushToDataLayer({
    event: "view_item",
    ecommerce: eventData,
  });
  const statsigData = viewItemToStatsig(eventData);
  Statsig.logEvent(
    statsigData.eventName,
    statsigData.value,
    statsigData.metadata
  );
  if (checkStripeGate()) {
    amplitude.track("view_item", {
      value: statsigData.value,
      ...statsigData.metadata,
    });
  }
}

function sendSelectItemEvent(data: ItemEvent) {
  const items = data.items.map((item, index) =>
    toCommerceItem(data.itemListName, item, index)
  );
  const eventData: SelectItemEventEcom = {
    item_list_id: "related_products",
    item_list_name: "Related Products",
    items,
  };
  pushToDataLayer({
    event: "select_item",
    ecommerce: eventData,
  });
  if (checkStripeGate()) {
    amplitude.track("select_item", {
      ...eventData,
    });
  }
}

function sendAddToCartEvent(data: ItemEvent) {
  const items = data.items.map((item, index) =>
    toCommerceItem(data.itemListName, item, index)
  );
  const eventData: AddToCartEventEcom = {
    currency: "USD",
    value: items.reduce((acc, item) => acc + item.price, 0),
    items,
  };
  pushToDataLayer({
    event: "add_to_cart",
    ecommerce: eventData,
  });
  const statsigData = atcToStatsig(eventData);
  Statsig.logEvent(
    statsigData.eventName,
    statsigData.value,
    statsigData.metadata
  );
  if (checkStripeGate()) {
    amplitude.track("add_to_cart", {
      value: statsigData.value,
      ...statsigData.metadata,
    });
  }
}

type StatsigData = {
  eventName: string;
  value?: string | number | null | undefined;
  metadata?: Record<string, string> | null | undefined;
};

function viewItemToStatsig(data: ViewItemEventEcom): StatsigData {
  return {
    eventName: "view_item",
    value: String(data.value),
    metadata: {
      currency: data.currency,
      discount: String(data.items[0].discount),
      product_price: String(data.value),
      name: data.items[0].item_name,
      productId: data.items[0].item_id,
      source: "quiz",
    },
  };
}

function atcToStatsig(data: AddToCartEventEcom): StatsigData {
  return {
    eventName: "add_to_cart",
    value: String(data.value),
    metadata: {
      currency: data.currency,
      discount: String(data.items[0].discount),
      product_price: String(data.value),
      name: data.items[0].item_name,
      productId: data.items[0].item_id,
      source: "quiz",
    },
  };
}

export type { DataEvent, ItemEvent, QuizEventData };

export {
  sendPageViewEvent,
  sendChangesToGTM,
  sendViewItemListEvent,
  sendViewItemEvent,
  sendSelectItemEvent,
  sendAddToCartEvent,
  FunnelVariation,
  pushToDataLayer, // Do not use this directly. Use the other functions instead
};
