enum Focus {
  Nutrition = "Nutrition",
  Training = "Training",
  BuildingGoodHabits = "BuildingGoodHabits",
}

const focusQuestion = {
  name: "focus",
  title: "What would you like your plan to focus on first?",
  type: "radiogroup",
  choices: [
    {
      value: Focus.Nutrition,
      text: "Nutrition",
    },
    {
      value: Focus.Training,
      text: "Training",
    },
    {
      value: Focus.BuildingGoodHabits,
      text: "Building good habits",
    },
  ],
  isRequired: true,
  showOtherOption: true,
}

export {
  Focus,
  focusQuestion,
}