const dietSplashHtml = `
<div class="quote-container"> 
  <div class="quote">
    <div>Most diets are unsustainable, complicated, and don't take YOU into account. Welcome to something different.</div>
    <p>We don't believe in a "one diet fits all" plan. Built With Science builds your nutrition around you and your goals.</p>
  </div>
</div>`;

const dietSplashElement = {
  visibleIf: `{diet} > 3`,
  name: 'diet-splash',
  type: 'html',
  html: dietSplashHtml,
};

export {
  dietSplashElement,
}