
interface ChoicesQuestion {
  choices?: Array<string | number | { value: string }>;
}

function isChoicesQuestion(question: unknown): question is ChoicesQuestion {
  return typeof question === 'object' && question !== null && 'choices' in question;
}

export type { 
  ChoicesQuestion,
}

export {
  isChoicesQuestion,
}