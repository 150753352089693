enum Motivation {
  CONFIDENCE = 'Confidence',
  HEALTH = 'Health',
  FEEL_BETTER = 'Feel Better',
  PROVE_CAPABLE = 'Prove Capable',
}

const motivationQuestion = {
  name: 'motivation',
  type: 'radiogroup',
  // showOtherItem: true,
  colCount: 1,
  title: 'What is your main reason for wanting to get in shape?',
  choices: [
    { value: Motivation.CONFIDENCE, text: "Feel more confident about myself and my body" },
    { value: Motivation.HEALTH, text: "Become healthier" },
    { value: Motivation.FEEL_BETTER, text: "Feel better day-to-day" },
    { value: Motivation.PROVE_CAPABLE, text: "Prove to myself I'm capable of more" },
  ],
};

export {
  motivationQuestion,
  Motivation,
}