enum ActivityLevel {
  Sedentary = "sedentary",
  SomewhatActive = "somewhatActive",
  VeryActive = "veryActive",
}

const activityQuestion = {
  name: 'activity',
  title: 'How active are you throughout the day?',
  type: "radiogroup",
  choices: [
    {
      value: ActivityLevel.Sedentary,
      text: 'I sit for most of the day',
    },
    {
      value: ActivityLevel.SomewhatActive,
      text: `I have a fairly active job and I'm on my feet quite often`,
    },
    {
      value: ActivityLevel.VeryActive,
      text: `I'm very active and hardly ever sit still`,
    },
  ],
  isRequired: true,
};

export {
  ActivityLevel,
  activityQuestion,
}