import { FunctionFactory } from 'survey-core';

const consistencySplashHtml = `
<div class="quote-container"> 
  <div class="quote">
    {consistencyQuote}
  </div>
</div>`;

const consistencySplashExpression = {
  name: "consistencyQuote",
  expression: "renderConsistencyQuote({consistency})",
};

function renderConsistencyQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  return "That's very common. Habit building is the key to overcoming this. Most programs don't teach this. We make this easy for you";
}

FunctionFactory.Instance.register("renderConsistencyQuote", renderConsistencyQuote);

const consistencySplashElement = {
  visibleIf: `{consistency} > 3`,
  name: 'consistency-splash',
  type: 'html',
  html: consistencySplashHtml,
}

export {
  consistencySplashElement,
  consistencySplashExpression,
}