enum Nutrition {
  CookAtHome = "CookAtHome",
  EatOut = "EatOut",
  LiveWithFamily = "LiveWithFamily",
  Weekends = "Weekends",
  LateNightSnacking = "LateNightSnacking",
}

const nutritionQuestion = {
  name: "nutrition",
  title: "What best describes your nutrition habits during the week?",
  description: 'Pick all that apply',
  descriptionLocation: 'underTitle',
  type: "checkbox",
  choices: [
    {
      value: Nutrition.CookAtHome,
      text: `I cook most/all of my meals at home`,
    },
    {
      value: Nutrition.EatOut,
      text: `I eat out several times a week`,
    },
    {
      value: Nutrition.LiveWithFamily,
      text: `I live with family, so I'm not in charge of meal prep`,
    },
    {
      value: Nutrition.Weekends,
      text: `I eat well during the week, but I struggle on weekends`,
    },
    {
      value: Nutrition.LateNightSnacking,
      text: `I eat well during the day, but I struggle with late night snacking`,
    },
  ],
  // showOtherItem: true,
  isRequired: true,
};

const nutritionRestrictionsQuestion = {
  name: "nutritionRestrictions",
  title: "Do you have any of the following dietary restrictions or allergies?",
  description: 'Pick all that apply',
  descriptionLocation: 'underTitle',
  type: 'checkbox',
  choices: [
    'Vegan',
    'Vegetarian',
    'Gluten Free',
    'Dairy Free',
  ],
  showNoneItem: true,
  // showOtherItem: true,
};

export { Nutrition, nutritionQuestion, nutritionRestrictionsQuestion };
