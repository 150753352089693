import styled from "styled-components";

const PageContainer = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lato', sans-serif;
  background-color: #dadada;
  max-width: 100%;
  min-width: 300px;
`;

export {
  PageContainer
}