enum HeightUnit {
  Feet = "ft",
  Centimeters = "cm",
};

const heightToggle = {
  name: "heightUnit",
  title: "What is your height?",
  type: "boolean",
  labelTrue: HeightUnit.Centimeters,
  valueTrue: HeightUnit.Centimeters,
  labelFalse: HeightUnit.Feet,
  valueFalse: HeightUnit.Feet,
  defaultValue: HeightUnit.Feet,
};

const heightInputTemplate = {
  type: "text",
  inputType: "number",
  titleLocation: 'left',
}

const heightRaw = {
  ...heightInputTemplate,
  name: "heightRaw",
  isRequired: true,
  title: "{heightUnit}",
  validators: [{
    "type": "numeric",
    "minValue": 3,
    "maxValue": 300,
    text: "Please a valid number of {heightUnit}.",
  }],
};

const heightInches = {
  ...heightInputTemplate,
  name: "heightInches",
  startWithNewLine: false,
  visibleIf: `{heightUnit} = ${HeightUnit.Feet}`,
  title: "in",
  validators: [{
    "type": "numeric",
    "minValue": 0,
    "maxValue": 12,
    text: "Please a valid number of inches.",
  }],
};

const heightQuestion = {
  type: "panel",
  name: "height",
  titleLocation: "hidden",
  elements: [
    heightRaw,
    heightInches,
  ],
};

export { heightQuestion, HeightUnit, heightToggle};
