import { FunctionFactory } from 'survey-core';
import { Goals } from './goals';

const emailQuestion = {
  name: "email",
  title: "{firstname}, enter your email to see how long it will take to reach your {emailWeightGoalText} goal",
  type: "text",
  description: `<div class="disclaimer">
  <div>We'll email you a copy of your results for convenient access.</div><br />
  By clicking "See My Results" below you acknowledge that you have read, understood, and accepted the <span class="disclaimer__terms"><a href="https://builtwithscience.com/terms-of-use/" target="_blank">Terms of Use</a></span> and <span class="dislaimer__terms"><a href="https://builtwithscience.com/privacy-policy/" target="_blank">Privacy Policy</a></span>.  You may receive emails from Built With Science and can unsubscribe at any time.
  </div>`,
  descriptionLocation: "underInput",
  inputType: "email",
  isRequired: true,
  placeholder: "you@mail.com",
  autocomplete: "email",
};

const emailWeightGoalTextExpression = {
  name: "emailWeightGoalText",
  expression: "renderEmailWeightGoalTextExpression({weightGoal}, {goalBodyFat}, {muscleGain}, {goals})",
};

function renderEmailWeightGoalTextExpression(params: any[]): string {
  if (params === undefined || params === null) return "";
  // const weightRaw = params[0]?.weightRaw as number;
  // const goalBodyFat = params[1] as BodyFat;
  // const muscleGain = params[2] as number;
  const goals = params[3] as Goals;

  switch (goals) {
    case Goals.LoseFat:
      return `weight loss`;
    case Goals.BuildMuscle:
      return `muscle gain`;
    case Goals.BuildMuscleAndLoseFat:
      return `fat loss`
  }
}

FunctionFactory.Instance.register("renderEmailWeightGoalTextExpression", renderEmailWeightGoalTextExpression);

export {
  emailQuestion,
  emailWeightGoalTextExpression,
};
