import { PriceBlockProps } from "./PriceBlock";
import { HeadlineProps } from "./programLevelComponents";

type Feature = {
  text: string;
  isBlue?: boolean;
  bold?: boolean;
};

enum ProgramLevelTitle {
  Base = "Base",
  Premium = "Premium",
  Elite = "Elite",
}

type ProgramLevelProps = {
  title: ProgramLevelTitle;
  price: Omit<PriceBlockProps, "hasLimitedSupply">;
  features: Feature[];
  featureHeader?: string;
  buyLink: string;
  headline?: {
    title: string;
  } & HeadlineProps;
  image: string;
  mobileOrder: number;
  showLimitedSupply?: boolean;
  listName: string; // for google analytics
  name: string; // for google analytics
  productId: string; // for google analytics
};

export type { ProgramLevelProps, Feature };

export {
  ProgramLevelTitle,
}
