const durationQuestion = {
  type: 'radiogroup',
  name: 'duration',
  title: 'How long have you been trying to get in shape, or seriously thinking about getting your body in shape?',
  choices: [
    "0-6 months",
    "6-12 months",
    "1-3 years",
    "3+ years",
  ],
  isRequired: true,
}

export {
  durationQuestion,
}