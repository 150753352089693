import { DateTime, Interval } from 'luxon';
import { useMemo } from 'react';
import { Experience } from '../quiz/experience';
import { Goals } from '../quiz/goals';
import { MuscleGain } from '../quiz/goalMuscleGain';
import { Demographics } from '../types';
import { isArrayOf } from '../utils';
import { isBodyFatData, BodyFatChart } from './BodyFatChart';
import { ChartData, TitleStyle } from './Chart';
import { isMuscleGainData, MuscleGainChart } from './MuscleGainChart';
import { isWeightLossData, WeightLossChart } from './WeightLossChart';
import _ from 'lodash';
import { getWeightPounds } from '../quiz/weight';

type ChartCalculations = {
  timeToGoal: Interval;
  data: ChartData[];
};

// remove duplicate months
const getUniqueMonthlyData = (data: ChartData[]): ChartData[] => {
  const groupedData = _.groupBy(data, (entry) => `${entry.date.year}-${entry.date.month}`);
  return _.map(groupedData, (group) => group[group.length - 1]);
};

const getWeightLossData = (demographics: Demographics): ChartCalculations => {
  const {
    weightUnit,
    weight: { weightRaw },
    goalWeight,
  } = demographics;
  const weightPounds = getWeightPounds(weightRaw, weightUnit);
  const goalWeightPounds = getWeightPounds(goalWeight!.goalWeightRaw, weightUnit);
  let bodyWeightLbs = weightPounds;
  const currentDate = DateTime.now();
  let weeks = 0;
  while (goalWeightPounds < bodyWeightLbs) {
    bodyWeightLbs *= 0.993;
    weeks++;
  }

  // Generate the weekly data using lodash times function
  let weeklyData = _.times(weeks, (i) => {
    bodyWeightLbs = i === 0 ? weightPounds : bodyWeightLbs * 0.993;
    return {
      date: currentDate.plus({ weeks: i }),
      bodyWeightLbs,
    };
  });

  // we hardcode the last value to the goal value to avoid overshooting the goal
  _.last(weeklyData)!.bodyWeightLbs = goalWeightPounds;
  const weightDiff = weightPounds - goalWeightPounds;
  const interval = _.last(weeklyData)!.date.diff(currentDate);

  const fixedData = weeklyData.map(({ date, bodyWeightLbs: _ }) => ({
    date,
    bodyWeightLbs: weightPounds - (date.diff(currentDate).as('hours') / interval.as('hours')) * weightDiff,
  }));

  // Average out to at most five datapoints
  const maxDataPoints = 5;
  if (fixedData.length > maxDataPoints) {
    // We want to keep the first and last data points
    const first = fixedData[0];
    const last = fixedData[fixedData.length - 1];
    const interval = last.date.diff(first.date);
    const intervalPerPoint = interval.as('hours') / (maxDataPoints - 1);
    const newFixedData = [first];
    for (let i = 1; i < maxDataPoints - 1; i++) {
      const date = first.date.plus({ hours: intervalPerPoint * i });
      const bodyWeightLbs = weightPounds - (date.diff(currentDate).as('hours') / interval.as('hours')) * weightDiff;
      newFixedData.push({
        date,
        bodyWeightLbs,
      });
    }
    newFixedData.push(last);

    return {
      timeToGoal: currentDate.until(_.last(newFixedData)!.date),
      data: getUniqueMonthlyData(newFixedData),
    };
  }

  return {
    timeToGoal: currentDate.until(_.last(weeklyData)!.date),
    data: getUniqueMonthlyData(fixedData),
  };
};

const getMuscleGainData = (demographics: Demographics): ChartCalculations => {
  const { muscleGain, experience } = demographics;
  const goalMuscleGainLbs = (() => {
    switch (muscleGain) {
      case MuscleGain.NotSure:
      case MuscleGain.Max:
        return demographics.experience === Experience.Beginner ? 18 : 12;
      default:
        return muscleGain ?? 0;
    }
  })();
  const muscleGainSpeed = experience === Experience.Beginner ? 3 : 2;
  let months: number;
  const currentDate = DateTime.now();
  let muscleGainedLbs = 0;
  const monthlyData = [
    {
      date: currentDate,
      muscleGainedLbs,
    },
  ];
  for (months = 1; muscleGainedLbs < goalMuscleGainLbs; months++) {
    muscleGainedLbs += muscleGainSpeed;
    monthlyData.push({
      date: currentDate.plus({ months }),
      muscleGainedLbs,
    });
  }

  // we hardcode the last value to the goal value to avoid overshooting the goal
  _.last(monthlyData)!.muscleGainedLbs = goalMuscleGainLbs;
  const interval = _.last(monthlyData)!.date.diff(currentDate);
  const fixedData = monthlyData.map(({ date, muscleGainedLbs: _ }) => {
    return {
      date,
      muscleGainedLbs: (date.diff(currentDate).as('hours') / interval.as('hours')) * goalMuscleGainLbs,
    };
  });

  // Average out to at most five datapoints
  const maxDataPoints = 5;
  if (fixedData.length > maxDataPoints) {
    // We want to keep the first and last data points
    const first = fixedData[0];
    const last = fixedData[fixedData.length - 1];
    const interval = last.date.diff(first.date);
    const intervalPerPoint = interval.as('hours') / (maxDataPoints - 1);
    const newData = [first];
    for (let i = 1; i < maxDataPoints - 1; i++) {
      const date = first.date.plus({ hours: intervalPerPoint * i });
      const muscleGainedLbs = (date.diff(currentDate).as('hours') / interval.as('hours')) * goalMuscleGainLbs;
      newData.push({
        date,
        muscleGainedLbs,
      });
    }
    newData.push(last);

    return {
      timeToGoal: currentDate.until(_.last(newData)!.date),
      data: getUniqueMonthlyData(newData),
    };
  }

  return {
    timeToGoal: currentDate.until(_.last(monthlyData)!.date),
    data: getUniqueMonthlyData(fixedData),
  };
};

const getBodyFatLossData = (demographics: Demographics): ChartCalculations => {
  const { bodyFat, goalBodyFat } = demographics;
  let months: number;
  const currentDate = DateTime.now();
  let currentBodyFat = bodyFat;
  const monthlyData = [
    {
      date: currentDate,
      bodyFatPercentage: currentBodyFat,
    },
  ];
  for (months = 1; currentBodyFat > goalBodyFat!; months++) {
    currentBodyFat -= currentBodyFat < 17 ? 1.5 : 2;
    monthlyData.push({
      date: currentDate.plus({ months }),
      bodyFatPercentage: currentBodyFat,
    });
  }

  // we hardcode the last value to the goal value to avoid overshooting the goal
  _.last(monthlyData)!.bodyFatPercentage = goalBodyFat!;
  const bodyFatDiff = bodyFat - goalBodyFat!;
  const interval = _.last(monthlyData)!.date.diff(currentDate);
  const fixedData = monthlyData.map(({ date, bodyFatPercentage: _ }) => {
    return {
      date,
      bodyFatPercentage: bodyFat - (date.diff(currentDate).as('hours') / interval.as('hours')) * bodyFatDiff,
    };
  });

  // Average out to at most five datapoints
  const maxDataPoints = 5;
  if (fixedData.length > maxDataPoints) {
    // We want to keep the first and last data points
    const newData = [fixedData[0]];
    const intervalHours = interval.as('hours');
    const intervalHoursPerDataPoint = intervalHours / (maxDataPoints - 1);
    for (let i = 1; i < maxDataPoints - 1; i++) {
      const date = currentDate.plus({ hours: intervalHoursPerDataPoint * i });
      const bodyFatPercentage = bodyFat - (date.diff(currentDate).as('hours') / intervalHours) * bodyFatDiff;
      newData.push({
        date,
        bodyFatPercentage,
      });
    }

    newData.push(_.last(fixedData)!);

    return {
      timeToGoal: currentDate.until(_.last(newData)!.date),
      data: getUniqueMonthlyData(newData),
    };
  }

  // remove any duplicate months

  return {
    timeToGoal: currentDate.until(_.last(monthlyData)!.date),
    data: getUniqueMonthlyData(fixedData),
  };
};

const useChartCalculations = (demographics: Demographics) => {
  const { timeToGoal, data } = useMemo(() => {
    switch (demographics.goals) {
      case Goals.LoseFat:
        return getWeightLossData(demographics);
      case Goals.BuildMuscle:
        return getMuscleGainData(demographics);
      case Goals.BuildMuscleAndLoseFat:
        return getBodyFatLossData(demographics);
    }
  }, [demographics]);

  const chart = useMemo(() => {
    switch (demographics.goals) {
      case Goals.LoseFat:
        return isArrayOf(isWeightLossData)(data) && <WeightLossChart data={data} showTitle={TitleStyle.Text} weightUnit={demographics.weightUnit} />;
      case Goals.BuildMuscle:
        return isArrayOf(isMuscleGainData)(data) && <MuscleGainChart data={data} showTitle={TitleStyle.Text} weightUnit={demographics.weightUnit} />;
      case Goals.BuildMuscleAndLoseFat:
        return isArrayOf(isBodyFatData)(data) && <BodyFatChart data={data} showTitle={TitleStyle.Text} />;
    }
  }, [demographics, data]);

  return {
    timeToGoal,
    chart,
  };
};

export { getWeightLossData, getMuscleGainData, getBodyFatLossData, useChartCalculations };
