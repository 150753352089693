import styled from "styled-components";
import { ItemEvent, sendAddToCartEvent, sendSelectItemEvent } from "../../services/googleTagManager";
import { commonButtonStyle } from "../../common-ui/styles";

const ProgramBuyLink = styled.a`
  ${commonButtonStyle}
`;

type ProgramBuyButtonProps = {
  buyLink: string;
  itemEventData: ItemEvent;
  text?: string;
};

const ProgramBuyButton = (props: ProgramBuyButtonProps) => {
  const { buyLink, itemEventData } = props;
  return (
    <ProgramBuyLink href={buyLink} target="_self" onClick={(e) => {
      e.preventDefault();
      sendSelectItemEvent(itemEventData);
      sendAddToCartEvent(itemEventData);
      window.location.href = buyLink;
    }}>
      {props.text ?? 'START NOW'}
    </ProgramBuyLink>
  );
}

export default ProgramBuyButton;
