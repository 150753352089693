import { FunctionFactory } from 'survey-core';
import { CalorieTracking } from '../quiz/calories';
import { Goals } from '../quiz/goals';

const calorieSplashHtml = `
<div class="quote-container">
  <div class="quote">
    {calorieQuote}
  </div>
</div> 
`;

const calorieSplashExpression = {
  name: "calorieQuote",
  expression: "renderCalorieQuote({calories}, {goals})",
};

function renderCalorieQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as CalorieTracking) {
    case CalorieTracking.NoIdea: {
      const goalsCalorieText = (() => {
        switch (params[1] as Goals) {
          case Goals.LoseFat:
            return "lose fat";
          case Goals.BuildMuscle:
            return "build muscle";
          case Goals.BuildMuscleAndLoseFat:
            return "lose weight";
        }
      })();
      return `No problem! We'll show you how to start easily hitting your daily calorie goal to ${goalsCalorieText} even if you've never tracked before.`;
    }
    case CalorieTracking.EatingMore: {

      const goalsCalorieText = (() => {
        switch (params[1] as Goals) {
          case Goals.LoseFat:
            return "fat loss";
          case Goals.BuildMuscle:
            return "muscle building";
          case Goals.BuildMuscleAndLoseFat:
            return "weight loss";
        }
      })();
      return `No problem! We'll show you some easy tweaks you can make to your diet to get closer to your target calorie intake and hit your ${goalsCalorieText} goal.`;
    }
    case CalorieTracking.EatingLess: {
      const goalsCalorieText = (() => {
        switch (params[1] as Goals) {
          case Goals.LoseFat:
            return "fat loss";
          case Goals.BuildMuscle:
            return "muscle building";
          case Goals.BuildMuscleAndLoseFat:
            return "weight loss";
        }
      })();
      return `Consistently undereating can lead to low energy and muscle loss. Don't worry, we'll show you some easy tweaks you can make to your diet to get closer to your target calorie intake and hit your ${goalsCalorieText} goal.`;
    }
    case CalorieTracking.PrettyClose: {
      return `Great! You're one step ahead - just make sure you're tracking your calories accurately. Let's see if there's another area with nutrition you can improve on.`;
    }
  }
}

FunctionFactory.Instance.register("renderCalorieQuote", renderCalorieQuote);

const calorieSplashElement = {
  name: 'calorie-splash',
  type: 'html',
  html: calorieSplashHtml,
}

export {
  calorieSplashElement,
  calorieSplashExpression,
}