import { FunctionFactory } from 'survey-core';
import { BusyLevel } from '../quiz/busy';

const busyLevelSplashHtml = `
<div class="quote-container">
  <img src="https://cdn.builtwithscience.com/images/splash/busyLevel.jpg" alt="Testimonial from a user like you!" />
  <div class="quote">
    {busyLevelQuote}
  </div>
</div>`;

const busyLevelSplashExpression = {
  name: "busyLevelQuote",
  expression: "renderBusyLevelQuote({busyLevel})",
};

function renderBusyLevelQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as BusyLevel) {
    case BusyLevel.NoTime:
    case BusyLevel.FairlyBusy:
      return `We dont expect you to make fitness your full time job. We know you're busy. We'll show you how to make your plan fit YOUR busy lifestyle.`;
    case BusyLevel.NotBudy:
    case BusyLevel.Flexible:
      return `Great! We don't expect you to make fitness your full time job. We'll find a suitable training plan that works with your schedule and goals.`;
  }
}

FunctionFactory.Instance.register("renderBusyLevelQuote", renderBusyLevelQuote);

const busyLevelSplashElement = {
  name: 'busy-level-splash',
  type: 'html',
  html: busyLevelSplashHtml,
}

export {
  busyLevelSplashElement,
  busyLevelSplashExpression,
}
