import { CDN_ROOT } from "../features/images";
import { Gender } from "./gender";
import { Goals } from "./goals";

enum BodyFat {
  Ten = 10,
  Twelve = 12,
  Fifteen = 15,
  Eighteen = 18,
  Twenty = 20,
  TwentyFive = 25,
  Thirty = 30,
  ThirtyFive = 35,
  Fourty = 40,
  OverFourtyFive = 45,
}

const bodyFatQuestion = {
  name: "bodyFat",
  title: "What would you estimate your current body fat % to be?",
  description: "Pick what best describes your current state",
  type: "imagepicker",
  showLabel: true,
  isRequired: true,
  colCount: 0,
}

const bodyFatMaleQuestion = {
  ...bodyFatQuestion,
  visibleIf: `{gender} = "${Gender.Male}"`,
  choices: [
    {
      "value": BodyFat.ThirtyFive,
      "imageLink": `${CDN_ROOT}/images/bodyfat/male35.jpg`,
      "text": "35% and above",
    },
    {
      "value": BodyFat.Thirty,
      "imageLink": `${CDN_ROOT}/images/bodyfat/male30.jpg`,
      "text": "Around 30%",
    },
    {
      "value": BodyFat.TwentyFive,
      "imageLink": `${CDN_ROOT}/images/bodyfat/male25.jpg`,
      "text": "Around 25%",
    },
    {
      "value": BodyFat.Twenty,
      "imageLink": `${CDN_ROOT}/images/bodyfat/male20.jpg`,
      "text": "Around 20%",
    },
    {
      "value": BodyFat.Fifteen,
      "imageLink": `${CDN_ROOT}/images/bodyfat/male15.jpg`,
      "text": "Around 15%",
    },
    {
      "value": BodyFat.Twelve,
      "imageLink": `${CDN_ROOT}/images/bodyfat/male12.jpg`,
      "text": "Around 12%",
    },
    {
      "value": BodyFat.Ten,
      "imageLink": `${CDN_ROOT}/images/bodyfat/male10.jpg`,
      "text": "Around 10%",
      visibleIf: `{goals} != "${Goals.BuildMuscleAndLoseFat}"`
    },
  ],
};

const bodyFatFemaleQuestion = {
  ...bodyFatQuestion,
  visibleIf: `{gender} = "${Gender.Female}"`,
  choices: [
    {
      "value": BodyFat.OverFourtyFive,
      "imageLink": `${CDN_ROOT}/images/bodyfat/female45.jpg`,
      "text": "45% and above",
    },
    {
      "value": BodyFat.Fourty,
      "imageLink": `${CDN_ROOT}/images/bodyfat/female40.jpg`,
      "text": "Around 40%",
    },
    {
      "value": BodyFat.ThirtyFive,
      "imageLink": `${CDN_ROOT}/images/bodyfat/female35.jpg`,
      "text": "Around 35%",
    },
    {
      "value": BodyFat.Thirty,
      "imageLink": `${CDN_ROOT}/images/bodyfat/female30.jpg`,
      "text": "Around 30%",
    },
    {
      "value": BodyFat.TwentyFive,
      "imageLink": `${CDN_ROOT}/images/bodyfat/female25.jpg`,
      "text": "Around 25%",
    },
    {
      "value": BodyFat.Twenty,
      "imageLink": `${CDN_ROOT}/images/bodyfat/female20.jpg`,
      "text": "Around 20%",
    },
    {
      "value": BodyFat.Eighteen,
      "imageLink": `${CDN_ROOT}/images/bodyfat/female18.jpg`,
      "text": "Around 18%",
    },
    {
      "value": BodyFat.Fifteen,
      "imageLink": `${CDN_ROOT}/images/bodyfat/female15.jpg`,
      "text": "Around 15%",
    },
  ],
};

export { BodyFat, bodyFatFemaleQuestion, bodyFatMaleQuestion };
