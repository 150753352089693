enum MuscleFocus {
  Chest = 'Chest',
  Back = 'Back',
  Arms = 'Arms',
  Shoulders = 'Shoulders',
  Legs = 'Legs',
  Glutes = 'Glutes',
  Calves = 'Calves',
  Equally = 'Equally',
}
const muscleFocusQuestion = {
  name: 'muscleFocus',
  title: 'What muscle group(s) do you want to focus on building the most?',
  description: 'Select up to 3 muscle groups',
  descriptionLocation: 'underTitle',
  type: 'checkbox',
  choices: [
    {
      value: MuscleFocus.Chest,
      text: 'Chest',
    },
    {
      value: MuscleFocus.Back,
      text: 'Back',
    },
    {
      value: MuscleFocus.Arms,
      text: 'Arms',
    },
    {
      value: MuscleFocus.Shoulders,
      text: 'Shoulders',
    },
    {
      value: MuscleFocus.Legs,
      text: 'Legs',
    },
    {
      value: MuscleFocus.Glutes,
      text: 'Glutes',
    },
    {
      value: MuscleFocus.Calves,
      text: 'Calves',
    },
    {
      value: MuscleFocus.Equally,
      text: 'I want to grow everything equally',
    },
  ],
  isRequired: true,
  maxSelectedChoices: 3,
};

export { MuscleFocus, muscleFocusQuestion };