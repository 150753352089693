import { faWeightScale } from "@fortawesome/free-solid-svg-icons";
import { Chart, ChartData, CustomTooltipProps, TitleStyle, TooltipContainer, TooltipText } from "./Chart";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { Guard } from "../utils";
import _ from "lodash";
import { WeightUnit, getWeightKg } from "../quiz/weight";

const CustomTooltip = ({ active, payload }: CustomTooltipProps): JSX.Element => {
  if (active && payload && payload.length) {
    const weight = payload[0].value as number
    return (
      <TooltipContainer>
        <TooltipText>{`Weight: ${weight.toPrecision(4)} lbs`}</TooltipText>
      </TooltipContainer>
    );
  } else {
    return <></>;
  }
};

type WeightLossChartData = ChartData & {
  bodyWeightLbs: number;
};

const isWeightLossData: Guard<WeightLossChartData> = (data: unknown): data is WeightLossChartData => {
  return (data as WeightLossChartData).bodyWeightLbs !== undefined;
};

type WeightLossChartProps = {
  data: WeightLossChartData[];
  showTitle?: TitleStyle;
  weightUnit: WeightUnit;
}

const WeightLossChart = (props: WeightLossChartProps) => {
  const { data, showTitle, weightUnit } = props;
  const hideYear = useMemo(() => data[data.length - 1].date.year === DateTime.local().year, [data]);

  if (data != null && data.length > 0) {
    const predictedDate = _.last(data)!.date.toFormat(`d MMM ${hideYear ? '' : 'yyyy'}`);
    const titlePrefix = TitleStyle.Text === showTitle ? 'We predict you\'ll be' : `YOUR WEIGHT LOSS`;
    const lastDataPoint = _.last(data)!.bodyWeightLbs;
    const endWeight = weightUnit === WeightUnit.Pounds ? lastDataPoint : getWeightKg(lastDataPoint, WeightUnit.Pounds);
    const totalLost = weightUnit === WeightUnit.Pounds
      ? data[0].bodyWeightLbs - lastDataPoint
      : getWeightKg(data[0].bodyWeightLbs - lastDataPoint, WeightUnit.Pounds);
    const titlePrediction = `${endWeight} ${weightUnit} by ${predictedDate}`;
    const finalMarker = `Weight Lost: ${totalLost} ${weightUnit}`;

    return <Chart
      data={data.map((d) => ({ ...d, bodyWeight: weightUnit === WeightUnit.Kilograms ? getWeightKg(d.bodyWeightLbs, WeightUnit.Pounds) : d.bodyWeightLbs }))}
      dataKey="bodyWeight"
      showTitle={showTitle}
      icon={faWeightScale}
      CustomTooltip={CustomTooltip}
      titlePrefix={titlePrefix}
      titlePrediction={titlePrediction}
      finalMarker={finalMarker}
      caret={'▼'}
      markerLocation="bottom"
      yAxisLabel={`Weight (${weightUnit})`}
    />;
  } else {
    return <></>;
  }
};

export {
  WeightLossChart,
  isWeightLossData,
};
export type { WeightLossChartProps, WeightLossChartData };
