import { ratingQuestionTemplate } from "./utils";

const dietQuestion = {
  ...ratingQuestionTemplate,
  name: 'diet',
  title: `How strongly do you relate to the below statement?

<span class="quote-container">"I know how important my diet is but I just don't know where to start or how to do it in a way that easily fits into MY life."</span>`,
};

export {
  dietQuestion,
}