enum TimeAvailable {
  ThirtyMinutes = '30OrLess',
  FourtyFiveMinutes = '45',
  SixtyMinutes = '60',
  NinetyMinutes = '90',
}

const timeAvailableQuestion = {
  name: 'timeAvailable',
  title: 'How much time do you have available for each workout?',
  description: `More isn't always better. Choose what you'll be most CONSISTENT with`,
  descriptionLocation: 'underTitle',
  type: 'radiogroup',
  choices: [
    {
      value: TimeAvailable.ThirtyMinutes,
      text: '30 minutes or less',
    },
    {
      value: TimeAvailable.FourtyFiveMinutes,
      text: '45 minutes',
    },
    {
      value: TimeAvailable.SixtyMinutes,
      text: '60 minutes',
    },
    {
      value: TimeAvailable.NinetyMinutes,
      text: '90 minutes',
    },
  ],
  isRequired: true,
};

export {
  TimeAvailable,
  timeAvailableQuestion,
}