
const shameQuestion = {
  name: 'shame',
  title: 'Do you ever feel insecure or ashamed of your body around friends and family or in public settings?',
  type: 'radiogroup',
  choices: [
    "Yes",
    "Sometimes",
    "No",
  ],
  isRequired: true,
};

export {
  shameQuestion,
}