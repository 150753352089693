import _ from "lodash";
import { CDN_ROOT } from "../features/images";

import { Gender } from "../quiz/gender";
import { Goals } from "../quiz/goals";

const motivationals = [
  {
    name: "Salma",
    gender: Gender.Female,
    goals: Goals.BuildMuscle,
    image: `${CDN_ROOT}/images/testimonials/salma.jpg`,
    displayName: "Salma T.",
  },
  {
    name: "Brian",
    gender: Gender.Male,
    goals: Goals.BuildMuscle,
    image: `${CDN_ROOT}/images/testimonials/brian.jpg`,
    displayName: "Brian K.",
  },
  {
    name: "Adam",
    gender: Gender.Male,
    goals: Goals.BuildMuscleAndLoseFat,
    image: `${CDN_ROOT}/images/testimonials/adam.jpg`,
    displayName: "Adam C.",
  },
  {
    name: "Reinis",
    gender: Gender.Male,
    goals: Goals.LoseFat,
    image: `${CDN_ROOT}/images/testimonials/reinis.jpg`,
    displayName: "Reinis S.",
  },
  {
    name: "Jade",
    gender: Gender.Female,
    goals: Goals.LoseFat,
    image: `${CDN_ROOT}/images/testimonials/jade.jpg`,
    displayName: "Jade D.",
  },
  {
    name: "Ligia",
    gender: Gender.Female,
    goals: Goals.BuildMuscle,
    image: `${CDN_ROOT}/images/testimonials/ligia.jpg`,
    displayName: "Ligia R.",
  },
  {
    name: "Jesse",
    gender: Gender.Male,
    goals: Goals.BuildMuscle,
    image: `${CDN_ROOT}/images/testimonials/jesse.jpg`,
    displayName: "Jesse K.",
  },
];

const PATRICK_DEFAULT = {
  name: "Patrick R.",
  gender: Gender.Male,
  goals: Goals.BuildMuscleAndLoseFat,
  testimonial: `“I've always struggled with stubborn fat but also a stubborn chest that I had a really hard time growing. After joining Built With Science I realized all the mistakes I was making. Not only did I lose the fat, but my chest filled out and is now one of my strongest muscle groups!”`,
  image: `${CDN_ROOT}/images/testimonials/patrick.jpg`,
  displayName: "Patrick R.",
} as const;

const testimonials = [
  PATRICK_DEFAULT,
  {
    name: "Stephen",
    gender: Gender.Male,
    goals: Goals.BuildMuscleAndLoseFat,
    testimonial: `To put this into perspective, here's Built With Science Member Stephen, who reached his goal in 6 months:`,
    image: `${CDN_ROOT}/images/testimonials/stephen.jpg`,
    displayName: "Stephen D.",
  },
  {
    name: "Cesar",
    gender: Gender.Male,
    goals: Goals.LoseFat,
    testimonial: `To put this into perspective, here's Cesar, who lost 20 pounds in a little over 2 months:`,
    image: `${CDN_ROOT}/images/testimonials/cesar.jpg`,
    displayName: "Cesar R.",
  },
  {
    name: "Abdullah",
    gender: Gender.Male,
    goals: Goals.BuildMuscle,
    testimonial: `To put this into perspective, here's what 16 lbs of pure muscle gain looks like:`,
    image: `${CDN_ROOT}/images/testimonials/abdullah.jpg`,
    displayName: "Abdullah K.",
  },
  {
    name: "Katy",
    gender: Gender.Female,
    goals: Goals.LoseFat,
    testimonial: `To put this into perspective, here's Katy who dropped 8% in body fat while gaining a significant amount of muscle:`,
    image: `${CDN_ROOT}/images/testimonials/katy.jpg`,
    displayName: "Katy L.",
  },
  {
    name: "Tahnee",
    gender: Gender.Female,
    goals: Goals.BuildMuscle,
    testimonial: `To put this into perspective, here's what 10 lbs of pure muscle gain looks like:`,
    image: `${CDN_ROOT}/images/testimonials/tahnee.jpg`,
    displayName: "Tahnee S.",
  },
  {
    name: "Salma",
    gender: Gender.Female,
    goals: Goals.BuildMuscle,
    testimonial: `To put this into perspective, here's Built With Science Member Salma, who reached her goal in just 2 months:`,
    image: `${CDN_ROOT}/images/testimonials/salma.jpg`,
    displayName: "Salma T.",
  },
  {
    name: "Tahnee S.",
    gender: Gender.Female,
    goals: Goals.BuildMuscle,
    testimonial: `“I've had a “flat butt” my whole life. I thought I was genetically stuck with it. After joining Built with Science and fixing all the mistakes I was making, I finally started to see results. I'd never in a million years think my body could ever look like this.”`,
    image: `${CDN_ROOT}/images/testimonials/tahnee.jpg`,
    displayName: "Tahnee S.",
  },
] as const;


const MOTIVATIONAL_BY_NAME = _.keyBy(motivationals, "name");

function getMotivationalByName(name: keyof typeof MOTIVATIONAL_BY_NAME) {
  return MOTIVATIONAL_BY_NAME[name];
}

const TESTIMONIAL_BY_NAME = _.keyBy(testimonials, "name");

function getTestimonialByName(name: keyof typeof TESTIMONIAL_BY_NAME) {
  return TESTIMONIAL_BY_NAME[name];
}

type MotivationalKey = keyof typeof motivationals[number];
type TestimonialKey = keyof typeof testimonials[number];

export type { MotivationalKey, TestimonialKey };

export { getMotivationalByName, getTestimonialByName };
