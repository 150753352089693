import { FunctionFactory } from 'survey-core';
import { ProteinTracking } from '../quiz/protein';

const proteinSplashHtml = `
<div class="quote-container">
  <div class="quote">
    {proteinQuote}
  </div>
</div> 
`;

const proteinSplashExpression = {
  name: "proteinQuote",
  expression: "renderProteinQuote({protein})",
};

function renderProteinQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as ProteinTracking) {
    case ProteinTracking.NoIdea:
      return `No worries! Most people have never tracked their protein intake. We'll show you how to start hitting your protein target with ease.`;
    case ProteinTracking.EatingMore:
      return `Great! You're one step ahead! We'll show you how to continue hitting your protein target with ease, and double check you're eating the highest quality protein sources.`;
    case ProteinTracking.EatingLess:
      return `No worries! Most people aren't eating enough protein. We'll show you how to start hitting your protein target with ease.`;
    case ProteinTracking.PrettyClose:
      return `Great! You're one step ahead! We'll show you how to easily hit your protein target everyday, and double check you're eating the highest quality protein sources.`;
  }
}

FunctionFactory.Instance.register("renderProteinQuote", renderProteinQuote);

const proteinSplashElement = {
  name: 'protein-splash',
  type: 'html',
  html: proteinSplashHtml,
}

export {
  proteinSplashElement,
  proteinSplashExpression,
}
