import { FunctionFactory } from 'survey-core';

enum Goals {
  BuildMuscleAndLoseFat = "Lose Fat Most Important",
  BuildMuscle = "Build Muscle Most Important",
  LoseFat = "Lose Weight",
}

const goalsQuestion = {
  name: "goals",
  title: "What is your main focus right now?",
  type: "radiogroup",
  choices: [
    {
      value: Goals.LoseFat,
      text: "I'm only focused on losing weight",
    },
    {
      value: Goals.BuildMuscle,
      text: "Building muscle is more important than losing fat",
    },
    {
      value: Goals.BuildMuscleAndLoseFat,
      text: "I'd like to build some muscle while I lose fat",
    },
  ],
  isRequired: true,
};

function renderGoalsText(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch (params[0] as Goals) {
    case Goals.LoseFat:
      return "lose weight";
    case Goals.BuildMuscle:
      return "gain muscle";
    case Goals.BuildMuscleAndLoseFat:
      return "build muscle and lose fat";
  }
}

const goalsTextExpression = {
  name: "goalsText",
  expression: "renderGoalsText({goals})",
};

FunctionFactory.Instance.register("renderGoalsText", renderGoalsText);

export {
  Goals,
  goalsQuestion,
  goalsTextExpression,
};
