import { FunctionFactory } from 'survey-core';
import { ComfortLevel } from '../quiz/comfort';

const comfortSplashHtml = `
<div class="quote-container"> 
  <div class="quote">
    {comfortQuote}
  </class>
</div>`;

const comfortSplashExpression = {
  name: "comfortQuote",
  expression: "renderComfortQuote({comfort})",
};

function renderComfortQuote(params: any[]): string {
  if (params === undefined || params === null) return "";
  switch(params[0] as ComfortLevel) {
    case ComfortLevel.Never:
      return `Don't worry. These exercises can be intimidating at first, but they're easy to learn. We'll teach you everything you need to know and show you some easier variations to start with.`;
    case ComfortLevel.Learning:
      return `Awesome! We'll show you how to perfect your form, and also show you some variations that might be a better fit for your body.`;
    case ComfortLevel.Reguarly:
      return `Awesome! We'll show you how to further improve your form and give you some new variations to try out for more gains and to break through plateaus.`;
    case ComfortLevel.CantDo:
      return `Don't worry, there are no "must do exercises". We'll show you some alternatives you can do to work around your injury or equipment limitations.`;
  }
}

FunctionFactory.Instance.register("renderComfortQuote", renderComfortQuote);

const comfortSplashElement = {
  name: 'comfort-splash',
  type: 'html',
  html: comfortSplashHtml,
}

export {
  comfortSplashElement,
  comfortSplashExpression,
}
