
enum ComfortLevel {
  Never = 'Never',
  Learning = 'Learning',
  Reguarly = 'Regularly',
  CantDo = 'CantDo',
}

const comfortQuestion = {
  name: 'comfort',
  title: 'How comfortable are you with the squat, bench, and deadlift?',
  type: 'radiogroup',
  choices: [
    {
      value: ComfortLevel.Never,
      text: `I've never done them, they intimidate me`,
    },
    {
      value: ComfortLevel.Learning,
      text: `I'm still learning proper form`,
    },
    {
      value: ComfortLevel.Reguarly,
      text: `I do them regularly with good form`,
    },
    {
      value: ComfortLevel.CantDo,
      text: "I can't do them because of past injuries",
    },
  ],
  isRequired: true,
};

export {
  ComfortLevel,
  comfortQuestion,
}