enum Injuries {
  Shoulder = 'Shoulder',
  Elbow = 'Elbow',
  LowerBack = 'Lower Back',
  Knee = 'Knee',
}

const injuriesQuestion = {
  name: 'injuries',
  title: 'Do you have any past injuries?',
  description: 'Select all that apply',
  descriptionLocation: 'underTitle',
  type: 'checkbox',
  choices: [
    {
      value: Injuries.Shoulder,
      text: 'Shoulder Injury',
    },
    {
      value: Injuries.Elbow,
      text: 'Elbow Injury',
    },
    {
      value: Injuries.LowerBack,
      text: 'Lower back Injury',
    },
    {
      value: Injuries.Knee,
      text: 'Knee Injury',
    },
  ],
  // showOtherItem: true,
  showNoneItem: true,
  isRequired: true,
};

export {
  Injuries,
  injuriesQuestion,
}