import { Experience } from "../quiz/experience";
import { Goals } from "../quiz/goals";
import { Gender } from "../quiz/gender";
import { ActivityLevel } from "../quiz/activity";
import { BodyFat } from "../quiz/bodyfat";

type WCTMapping = {
  [gender in Gender]: {
    [experience in Experience]: {
      [goal in Goals]: {
        upper: number;
        lower: number;
        wcm: number;
      }[];
    };
  };
};

const wctMapping: WCTMapping = {
  [Gender.Male]: {
    [Experience.Beginner]: {
      [Goals.BuildMuscleAndLoseFat]: [
        {
          upper: 1,
          lower: 0,
          wcm: 0,
        },
      ],
      [Goals.LoseFat]: [
        {
          upper: 1,
          lower: 0.2,
          wcm: -0.007,
        },
        {
          upper: 0.2,
          lower: 0.12,
          wcm: -0.005,
        },
        {
          upper: 0.12,
          lower: 0,
          wcm: -0.003,
        },
      ],
      [Goals.BuildMuscle]: [
        {
          upper: 1,
          lower: 0,
          wcm: 0.00375,
        },
      ],
    },
    [Experience.Intermediate]: {
      [Goals.BuildMuscleAndLoseFat]: [
        {
          upper: 1,
          lower: 0,
          wcm: 0,
        },
      ],
      [Goals.LoseFat]: [
        {
          upper: 1,
          lower: 0.15,
          wcm: -0.007,
        },
        {
          upper: 0.15,
          lower: 0.12,
          wcm: -0.005,
        },
        {
          upper: 0.12,
          lower: 0,
          wcm: -0.003,
        },
      ],
      [Goals.BuildMuscle]: [
        {
          upper: 1,
          lower: 0,
          wcm: 0.0025,
        },
      ],
    },
  },
  [Gender.Female]: {
    [Experience.Beginner]: {
      [Goals.BuildMuscleAndLoseFat]: [
        {
          upper: 1,
          lower: 0,
          wcm: 0,
        },
      ],
      [Goals.LoseFat]: [
        {
          upper: 1,
          lower: 0.3,
          wcm: -0.007,
        },
        {
          upper: 0.3,
          lower: 0.22,
          wcm: -0.005,
        },
        {
          upper: 0.22,
          lower: 0,
          wcm: -0.003,
        },
      ],
      [Goals.BuildMuscle]: [
        {
          upper: 1,
          lower: 0,
          wcm: 0.0025,
        },
      ],
    },
    [Experience.Intermediate]: {
      [Goals.BuildMuscleAndLoseFat]: [
        {
          upper: 1,
          lower: 0,
          wcm: 0,
        },
      ],
      [Goals.LoseFat]: [
        {
          upper: 1,
          lower: 0.25,
          wcm: -0.007,
        },
        {
          upper: 0.25,
          lower: 0.22,
          wcm: -0.005,
        },
        {
          upper: 0.22,
          lower: 0,
          wcm: -0.003,
        },
      ],
      [Goals.BuildMuscle]: [
        {
          upper: 1,
          lower: 0,
          wcm: 0.00125,
        },
      ],
    },
  },
};

const getWeightChangeMultiplier = (gender: Gender, experience: Experience, goal: Goals, bodyFat: number) => {
  const ranges = wctMapping[gender]?.[experience]?.[goal];
  if (!ranges) {
    return 0;
  }
  for (let range of ranges) {
    if (bodyFat < range.upper && bodyFat >= range.lower) {
      return range.wcm;
    }
  }
  return 0;
};

const getActivityMultiplier = (activityLevel: ActivityLevel) => {
  switch (activityLevel) {
    case ActivityLevel.Sedentary:
      return 1.45;
    case ActivityLevel.SomewhatActive:
      return 1.55;
    case ActivityLevel.VeryActive:
      return 1.725;
  }
};

const calculateTdeeI = (lbs: number, bodyfat: BodyFat, activityLevel: ActivityLevel): number => {
  const leanMass = lbs * (1 - bodyfat / 100);
  const activityMultiplier = getActivityMultiplier(activityLevel);
  return parseInt(((370 + 9.82 * leanMass) * activityMultiplier).toPrecision(4));
};

const calculateWeightChangeTarget = (wcm: number, weight: number) => {
  return wcm * weight;
};

const calTarget = (wcm: number, weightLbs: number, tdee: number, gender: Gender) => {
  const weightChangeTarget = calculateWeightChangeTarget(wcm, weightLbs);
  let calTarget = weightChangeTarget * 500 + tdee;
  return Math.max(gender === Gender.Male ? 1500 : 1200, Math.round(calTarget));
}

const calculateCalorieTarget = (experience: Experience, goal: Goals, bodyFat: BodyFat, gender: Gender, weightLbs: number, activityLevel: ActivityLevel): number => {
  const tdee = calculateTdeeI(weightLbs, bodyFat, activityLevel);
  const wcm = getWeightChangeMultiplier(gender, experience, goal, bodyFat / 100);
  return calTarget(wcm, weightLbs, tdee, gender);
};

const calculateProteinTarget = (gender: Gender, bodyFat: BodyFat, lbs: number) => {
  const bodyFatRate = bodyFat / 100;
  let proteinMultiplier = 1;
  if (gender === Gender.Male) {
    if (bodyFatRate > 0.25) {
      proteinMultiplier = 0.73;
    } else if (bodyFatRate >= 0.2) {
      proteinMultiplier = 0.8;
    }
  } else {
    if (bodyFatRate > 0.25) {
      proteinMultiplier = 0.8;
    }
  }
  return Math.round(proteinMultiplier * lbs);
};

// https://docs.google.com/document/d/11QSAPeoxanQVc40IcPauhBXCMFHpZdC14kEDtLZupAQ/edit

export {
  getWeightChangeMultiplier,
  calculateCalorieTarget,
  calculateProteinTarget,
  calculateWeightChangeTarget,
  calculateTdeeI,
};