
enum SelfDescription {
  DietAndTrainingNeedWork = 'DietAndTraining',
  StruggleConsistency = 'Consistency',
  SlowProgress = 'SlowProgress', 
}

const describeSelfQuestion = {
  name: "describeSelf",
  title: "Which best describes you?",
  type: "radiogroup",
  choices: [
    {
      value: SelfDescription.DietAndTrainingNeedWork,
      text: "My diet and training need a lot of work",
    },
    {
      value: SelfDescription.StruggleConsistency,
      text: "I have some healthy habits but struggle with consistency",
    },
    {
      value: SelfDescription.SlowProgress,
      text: "I mostly eat healthy and train regularly but the progress is slow",
    },
  ],
  isRequired: true,
};

export {
  SelfDescription,
  describeSelfQuestion,
}