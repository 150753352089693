import { FunctionFactory } from "survey-core";

enum Gender {
  Male = "Male",
  Female = "Female",
}


const genderQuestion = {
  name: "gender",
  title: `What gender do you identify with?`,
  description: `How you should train and eat will vary slightly based on your gender.`,
  descriptionLocation: 'underTitle',
  type: "radiogroup",
  choices: Object.values(Gender),
  isRequired: true,
};

const genderNounExpression = {
  name: "genderNoun",
  expression: "renderGender({gender})",
};

function renderGender(params: any[]): string {
  if (params === undefined || params === null) return "";
  return (params[0] as Gender) === Gender.Female ? "women" : "men";
}

FunctionFactory.Instance.register("renderGender", renderGender);

export { Gender, genderNounExpression, genderQuestion, renderGender };
