import { faWeightScale } from "@fortawesome/free-solid-svg-icons";
import { Chart, ChartData, CustomTooltipProps, TitleStyle, TooltipContainer, TooltipText } from "./Chart";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { Guard } from "../utils";
import _ from "lodash";

const CustomTooltip = ({ active, payload }: CustomTooltipProps): JSX.Element => {
  if (active && payload && payload.length) {
    const fat = payload[0].value as number;
    return (
      <TooltipContainer>
        <TooltipText>{`Body Fat: ${fat.toPrecision(2)} %`}</TooltipText>
      </TooltipContainer>
    );
  } else {
    return <></>;
  }
};

type BodyFatChartData = ChartData & {
  bodyFatPercentage: number;
};

const isBodyFatData: Guard<BodyFatChartData> = (data: unknown): data is BodyFatChartData => {
  return (data as BodyFatChartData).bodyFatPercentage !== undefined;
};

type BodyFatChartProps = {
  data: BodyFatChartData[];
  showTitle?: TitleStyle;
}

const BodyFatChart = (props: BodyFatChartProps) => {
  const { data, showTitle } = props;
  const showYear = useMemo(() => data[data.length - 1].date.year === DateTime.local().year, [data]);

  if (data != null && data.length > 0) {
    const predictedDate = _.last(data)!.date.toFormat(`d MMM ${showYear ? '' : 'yyyy'}`);
    const titlePrefix = TitleStyle.Text === showTitle ? 'We predict you\'ll be' : `YOUR BODY FAT LOSS`;
    const titlePrediction = `${_.last(data)!.bodyFatPercentage}% body fat by ${predictedDate}`;
    const finalMarker = `Body Fat: ${Math.round(_.last(data)!.bodyFatPercentage * 4) / 4}%`;

    return <Chart
      data={data}
      dataKey="bodyFatPercentage"
      showTitle={showTitle}
      icon={faWeightScale}
      CustomTooltip={CustomTooltip}
      titlePrefix={titlePrefix}
      titlePrediction={titlePrediction}
      finalMarker={finalMarker}
      caret={'▼'}
      markerLocation="bottom"
      yAxisLabel="Body Fat (%)"
    />;
  } else {
    return <></>;
  }
};

export {
  BodyFatChart,
  isBodyFatData,
};
export type { BodyFatChartData };
