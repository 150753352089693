import { Age } from "./age";

const studentQuestion = {
  name: "student",
  title: "Are you a student?",
  visibleIf: `{age} == ${Age.Teens} or {age} == ${Age.Twenties}`,
  type: "radiogroup",
  choices: [
    {
      value: true,
      text: "Yes",
    },
    {
      value: false,
      text: "No",
    },
  ],
  isRequired: true,
};

export {
  studentQuestion,
};
