import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

interface ProgressBarProps {
  strings: string[];
  onComplete?: () => void;
}

const DELAY_MILLIS = 1500;

const LoadingBar: React.FC<ProgressBarProps> = ({ strings, onComplete }) => {
  const [visibleIndex, setVisibleIndex] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (onComplete) {
        onComplete();
      }
    }, (strings.length + 1) * DELAY_MILLIS);

    return () => clearTimeout(timer);
  }, [strings.length, onComplete]);

  useEffect(() => {
    if (visibleIndex < strings.length) {
      const timer = setTimeout(() => {
        setVisibleIndex((prevIndex) => prevIndex + 1);
      }, visibleIndex * DELAY_MILLIS);

      return () => clearTimeout(timer);
    }
  }, [visibleIndex, strings.length]);

  const progress = Math.round((visibleIndex / strings.length) * 100);

  return (
    <Wrapper progress={progress}>
      <Text>
        {strings[visibleIndex] ?? 'Complete!'}
      </Text>
      <div className="progressbar-container">
        <span className="progress">{progress}%</span>
        <div className="progressbar-complete" style={{ width: `${progress}%` }}>
          <div className="progressbar-liquid"></div>
        </div>
      </div>
    </Wrapper>
  );
};

const TextAnimation = keyframes`
  from {
    transform: translateX(20px);
  }
  to {
    transform: translateX(0);
  }
`;


const Text = styled.span`
  width: 200px;
  animation: ${TextAnimation} 0.5s forwards;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-self: center;
`;

const Wrapper = styled.div<{ progress: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-bottom: 40px;

  position: relative;
  width: 400px;
  max-width: 100%;
  height: 50px;
  border: 1px solid #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .progressbar-complete {
    position: absolute;
    left: 0;
    top: 0px;
    height: 100%;
    background-color: #5225bd;
    border-radius: 10px;
    animation: g 2500ms infinite ease-in-out;
    z-index: 2;
    .progressbar-liquid {
      z-index: 1;
      width: 70px;
      height: 70px;
      animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
      position: absolute;
      right: -5px;
      top: -10px;
      background-color: #5225bd;
      border-radius: 40%;
    }
  }

  ${Text} {
    color: ${props => props.progress < 75 ? 'black' : 'white'};
  }

  .progress {
    position: relative;
    z-index: 3;
    color: ${props => props.progress < 75 ? 'black' : 'white'};
  }

  @keyframes g {
    0% { background-color: #5225bd; }
    50% { background-color: #607e9e; }
    100% { background-color: #5225bd; }
  }
  
  @keyframes r {
    from { transform: rotate(0deg); }
    from { transform: rotate(360deg); }
  }
`;


export default LoadingBar;
