import { Guard } from "./utils";
import _ from "lodash";
import { DemographicsMotivational } from "./DemographicsMotivational";
import { LandingPage } from "./LandingPage";
import { LoadingPage } from "./features/LoadingPage";
import { PageName, SURVEY_JSON } from "./quiz.config";

const COMPONENT_CONFIG = {
  'experience': {
    nextPage: 'email',
    component: LoadingPage,
    componentName: 'LoadingPage',
  },
  'email': {
    nextPage: 'duration',
    component: DemographicsMotivational,
    componentName: 'Post-Email Page',
  }
} as const;

function isTransitionComponentKey(key: string | null): key is keyof typeof COMPONENT_CONFIG {
  return key !== null && key in COMPONENT_CONFIG;
}

enum QuizSection {
  GETTING_STARTED = 'Getting Started',
  HABITS_AND_BEHAVIORS = 'Habits & Behaviors',
  TRAINING_AND_NUTRITION = 'Training & Nutrition',
};

type SectionConfig = {
  [key in QuizSection]: {
    startQuestion: PageName,
    endQuestion: PageName,
  }
}

type SectionTotal = {
  startIndex: number,
  endIndex: number,
  total: number,
}

type SectionTotals = {
  [key in QuizSection]: SectionTotal;
}

const SECTION_CONFIG: SectionConfig = {
  [QuizSection.GETTING_STARTED]: {
    startQuestion: 'goals',
    endQuestion: 'email',
  },
  [QuizSection.HABITS_AND_BEHAVIORS]: {
    startQuestion: 'duration',
    endQuestion: 'otherPrograms',
  },
  [QuizSection.TRAINING_AND_NUTRITION]: {
    startQuestion: 'science',
    endQuestion: 'focus',
  },
};

type ValueChoice = {
  value: string,
  text: string,
};

const isValueChoice: Guard<ValueChoice> = (value: any): value is ValueChoice => {
  return typeof value === 'object'
    && 'value' in value && typeof value.value === 'string'
    && 'text' in value && typeof value.text === 'string';
}

const findPageIndex = (name: string) => {
  let count = 0;

  for (const page of SURVEY_JSON.pages) {
    if (page.name === name) return count;
    const component = COMPONENT_CONFIG[page.name as keyof typeof COMPONENT_CONFIG];
    if (component) {
      count++;
      if (component.componentName === name) return count;
    }
    count++;
  }

  return -1;
}

const SECTION_QUESTION_COUNT: SectionTotals = _.entries(SECTION_CONFIG).reduce((acc, [section, { startQuestion, endQuestion }]) => {
  const startIndex = findPageIndex(startQuestion)
  const endIndex = findPageIndex(endQuestion);
  acc[section as QuizSection] = {
    startIndex,
    endIndex,
    total: endIndex - startIndex + 1,
  }
  return acc;
}, {} as SectionTotals);

const getSectionInfo = (section: QuizSection): SectionTotal => SECTION_QUESTION_COUNT[section];

const getQuestionSection = (questionName: string): QuizSection | undefined =>
  _.findKey(SECTION_CONFIG, (_value, section) => {
    const { startIndex, endIndex } = SECTION_QUESTION_COUNT[section as QuizSection];
    const questionIndex = findPageIndex(questionName);
    return questionIndex >= startIndex && questionIndex <= endIndex;
  }) as QuizSection | undefined;

export {
  isValueChoice,
  findPageIndex,
  getQuestionSection,
  getSectionInfo,
  COMPONENT_CONFIG,
  isTransitionComponentKey,
  QuizSection,
  SECTION_CONFIG,
  SURVEY_JSON,
};

export type {
  SectionConfig,
  SectionTotals,
}