enum OtherPrograms {
  OnlineFitnessProgram = 'OnlineFitnessProgram',
  SelfGuidedFitnessProgram = 'SelfGuidedFitnessProgram',
  PersonalTrainer = 'PersonalTrainer',
  CalorieCounting = 'CalorieCounting',
  PaidMealPlans = 'PaidMealPlans',
  Keto = 'Keto',
  IntermittentFasting = 'IntermittentFasting',
}


const otherProgramsQuestion = {
  name: 'otherPrograms',
  title: "Have you attempted any of the following in the past to {goalsText}?",
  description: 'Select all that apply',
  type: 'checkbox',
  choices: [
    {
      value: OtherPrograms.OnlineFitnessProgram,
      text: 'Paid for an online fitness program',
    },
    {
      value: OtherPrograms.SelfGuidedFitnessProgram,
      text: 'Created a program myself using free information online',
    },
    {
      value: OtherPrograms.PersonalTrainer,
      text: 'Personal Trainer',
    },
    {
      value: OtherPrograms.CalorieCounting,
      text: 'Calorie counting',
    },
    {
      value: OtherPrograms.PaidMealPlans,
      text: 'Paid meal plans',
    },
    {
      value: OtherPrograms.Keto,
      text: 'Keto',
    },
    {
      value: OtherPrograms.IntermittentFasting,
      text: 'Intermittent Fasting',
    },
  ],
  showNoneItem: true,
  isRequired: true,
};

export {
  OtherPrograms,
  otherProgramsQuestion,
}