import { DateTime } from "luxon";
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";

const TooltipContainer = styled.div`
  background-color: #dfb140;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const TooltipText = styled.p`
  font-family: "Lato", sans-serif;
  color: #000000;
  font-size: 14px;
  margin: 0;
`;

const ChartContainer = styled.div`
  font-family: "Oswald", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ChartTitleContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #406edf;
  color: #ffffff;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ChartTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1e1e1e;
  margin-bottom: 10px;
`;

const ChartTitlePrefix = styled.span`
  font-size: 16px;
`;

const IconTitle = styled.div`
  font-size: 18px;
  margin-left: 5px;
  margin-top: 3px;
`;

const ChartTitlePrediction = styled.h2`
  font-size: 22px;
  margin: 0;
`;

type ChartData = {
  date: DateTime;
};

enum TitleStyle {
  Text,
  Icon,
}

const formatDate = (date: number): string => {
  return DateTime.fromMillis(date).toFormat("MMM");
};

const CustomLabel = (props: any) => {
  const { viewBox, label, location, caret } = props;
  const { x: viewBoxX, y: viewBoxY, height: viewBoxHeight } = viewBox;

  const width = label.length * 7.5 + 20;
  const height = 30;
  const padding = 10;
  const ovalWidth = width + 2 * padding;
  const ovalHeight = height + 2 * padding;

  const xOffset = -ovalWidth / 2;
  const yOffset = 30;

  const startPointX = viewBoxX + xOffset + 10;
  const startPointY =
    location === "top" ? viewBoxHeight - 60 : viewBoxY + yOffset + 30;

  // Determine the end point of the arrow
  const endPointX = viewBoxX * 0.98;
  const endPointY = location === "top" ? 40 : 225;

  // Control point for the curve
  const controlPointX = (startPointX + endPointX) / 2 + 10;
  const controlPointY = (startPointY + endPointY) / 2;

  const arrowPath = `M${startPointX},${startPointY} Q${controlPointX},${controlPointY} ${endPointX},${endPointY}`;

  const yLocation =
    location === "top" ? viewBoxHeight - 25 : viewBoxY + yOffset;
  const isUp = caret === "▲";
  const caretColor = isUp ? "#6aa84f" : "#ea552b";
  const arrowColor = isUp ? "#535353" : "#787878";

  return (
    <g>
      <defs>
        <marker
          id="arrow"
          markerWidth="10"
          markerHeight="10"
          refX="0"
          refY="3"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M0,0 L0,6 L9,3 z" fill={arrowColor} />
        </marker>
      </defs>
      <ellipse
        cx={viewBoxX + xOffset}
        cy={yLocation}
        rx={ovalWidth / 2}
        ry={ovalHeight / 1.7}
        fill="#DFB140"
      />
      <text
        x={viewBoxX + xOffset}
        y={yLocation + 3}
        textAnchor="middle"
        alignmentBaseline="middle"
        fill="#000"
      >
        {label}
        <tspan fill={caretColor} dx="2" dy="4">
          {caret}
        </tspan>
      </text>
      <path
        d={arrowPath}
        fill="transparent"
        stroke={arrowColor}
        strokeWidth="3"
        markerEnd="url(#arrow)"
      />
    </g>
  );
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: Payload<ValueType, NameType>[];
};

type ChartProps = {
  data: ChartData[];
  dataKey: string;
  showTitle?: TitleStyle;
  icon: IconDefinition;
  titlePrefix?: string;
  titlePrediction?: string; // only shows up if showTitle is set to TitleStyle.Text
  CustomTooltip: React.FC<CustomTooltipProps>;
  finalMarker?: string | JSX.Element;
  caret: "▼" | "▲";
  markerLocation: "top" | "bottom";
  yAxisLabel?: string;
};

const Chart = (props: ChartProps) => {
  const {
    data,
    dataKey,
    showTitle,
    icon,
    titlePrefix,
    titlePrediction,
    caret,
    finalMarker,
    yAxisLabel,
    markerLocation,
  } = props;
  const chartdata = data.map((d) => ({ ...d, date: d.date.toMillis() }));

  const title = useMemo(() => {
    switch (showTitle) {
      case TitleStyle.Text:
        return (
          <ChartTitle>
            <ChartTitlePrefix>{titlePrefix}</ChartTitlePrefix>
            <ChartTitlePrediction>{titlePrediction}</ChartTitlePrediction>
          </ChartTitle>
        );
      case TitleStyle.Icon:
        return (
          <ChartTitleContainer>
            <FontAwesomeIcon icon={icon} />
            <IconTitle>{titlePrefix}</IconTitle>
          </ChartTitleContainer>
        );
      default:
        return <></>;
    }
  }, [icon, showTitle, titlePrefix, titlePrediction]);

  const marker = useMemo(() => {
    if (finalMarker) {
      return (
        <ReferenceLine
          x={chartdata[chartdata.length - 1].date}
          // segment={[{ x: chartdata[chartdata.length - 1].date, y: 0 }, { x: chartdata[chartdata.length - 1].date, y: 0 }]}
          stroke="#FF0000"
          strokeWidth={0}
          label={
            <CustomLabel
              label={finalMarker}
              caret={caret}
              location={markerLocation}
            />
          }
        />
      );
    }
    return <></>;
  }, [chartdata, finalMarker, markerLocation, caret]);

  return (
    <ChartContainer>
      {title}
      <ResponsiveContainer width={"90%"} height={300}>
        <AreaChart
          data={chartdata}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorWeight" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#406EDF" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#406EDF" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            type="number"
            tickFormatter={formatDate}
            domain={["dataMin, dataMax"]}
            scale={"time"}
          />
          <YAxis
            width={55}
            type="number"
            domain={["dataMin", "dataMax"]}
            tickCount={4}
            label={{ value: yAxisLabel, angle: -90, position: "insideLeft" }}
            scale={"linear"}
          />
          {/* <Tooltip content={payload => <CustomTooltip {...payload} />} wrapperStyle={{ border: 'none' }} /> */}
          <Area
            type="monotone"
            dataKey={dataKey}
            stroke="#406EDF"
            strokeWidth={4}
            fillOpacity={1}
            fill="url(#colorWeight)"
          />
          {marker}
        </AreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export { Chart, TooltipContainer, TooltipText, TitleStyle };
export type { ChartData, CustomTooltipProps };
