import { useMemo } from "react";
import { Goals } from "./quiz/goals";
import { User } from "./types";
import styled from "styled-components";
import { PageContainer } from "./common-ui/PageContainer";
import { Section } from "./common-ui/Section";
import { useChartCalculations } from "./charts/utils";
import { DateTime } from "luxon";

const NarrowSection = styled(Section)`
  max-width: 345px;
`;

const BodyWrapper = styled.div`
  width: 100%
  body {
    background: #eee;
    /* This is just a helper in case the element has a transparent background or white colors. */
  }

  .sd-title {
    color: #1e1e1e;
    flex-direction: row;
    white-space: normal;
    word-break: break-word;
  }

  * {
    scrollbar-color: #d6d6d6 transparent;
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    height: 12px;
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: transparent;
    border: 4px solid transparent;
    border-image: none 100% 1 0 stretch;
    border-radius: 32px;
  }

  ::-webkit-scrollbar-track {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: none;
    background-origin: padding-box;
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto;
  }

  .sd-title.sd-container-modern__title {
    align-items: center;
    box-shadow: #406edf 0 2px 0 0;
    column-gap: 32px;
    display: flex;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 2px;
    overflow: auto;
    padding: calc(3*8px);
    row-gap: 32px;
    width: 100%;
  }

  .sd-title.sd-container-modern__title .sd-logo__image {
    margin-top: 8px;
  }

  .sd-container-modern__title {
    background-color: #fff;
  }

  .sd-hidden {
    display: none !important;
  }
`;

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  justify-content: center;
  background-color: #add8e6;
`;

const ChartWrapper = styled(Section)`
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  appearance: none;
  background-color: white;
  background-repeat: repeat;
  border: 2px solid transparent;
  border-image: none 100% 1 0 stretch;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .15) 0 1px 2px;
  color: #406edf;
  cursor: pointer;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  outline: medium currentcolor;
  padding: 16px 64px;
  text-align: center;

  &:enabled {

  }

  &:not(:disabled):hover {
    box-shadow: #406edf 0 0 0 2px;
  }
`;

const ButtonSection = styled(NarrowSection)`
  display: flex;
  gap: 20px;
`;
const GOAL_TEXT = {
  [Goals.LoseFat]: `weight loss goal`,
  [Goals.BuildMuscle]: `muscle building goal`,
  [Goals.BuildMuscleAndLoseFat]: `body fat goals while building lean muscle`,
};

type Props = {
  user: User;
  onComplete: () => void;
}

const DemographicsMotivational = (props: Props) => {
  const { user, onComplete } = props;
  const { timeToGoal, chart } = useChartCalculations(user);
  const goalDate = timeToGoal.end!.toLocaleString(DateTime.DATE_MED);
  const goalsText = useMemo(() => GOAL_TEXT[user.goals], [user]);
  const motivationalText = `Great news! Based on Built With Science members like you we predict you'll be able to hit your ${goalsText} by ${goalDate} or earlier.`;

  return (
    <BodyWrapper>
      <StyledPageContainer>
        <ChartWrapper>
          {chart}
        </ChartWrapper>
        <NarrowSection>
          <div>{motivationalText}</div>
          <p>Next, tell us a bit more about your habits and behaviours so we can create the best plan for you.</p>
        </NarrowSection>
        <ButtonSection>
          <StyledButton onClick={onComplete}>Continue</StyledButton>
        </ButtonSection>
      </StyledPageContainer>
    </BodyWrapper >
  );
};

export {
  DemographicsMotivational,
}