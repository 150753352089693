import { FunctionFactory } from 'survey-core';
import { Gender } from "./gender";

enum AreaBody {
  Belly = 'Belly',
  LoveHandles = 'Love Handles',
  Chest = 'Chest',
  LowerBack = 'Lower Back',
  Legs = 'Legs',
  Arms = 'Arms',
  Hips = 'Hips',
  Equally = 'Equally',
}

const areaBodyQuestion = {
  name: 'areaBody',
  title: 'As a {areaBodyGenderText} what area(s) of your body is the most difficult for you to lose fat from?',
  description: 'Select up to 3 muscle groups',
  descriptionLocation: 'underTitle',
  choices: [
    {
      value: AreaBody.Belly,
      text: 'Belly',
    },
    {
      value: AreaBody.LoveHandles,
      text: 'Love Handles',
    },
    {
      value: AreaBody.Chest,
      text: 'Chest',
    },
    {
      value: AreaBody.LowerBack,
      text: 'Lower Back',
    },
    {
      value: AreaBody.Legs,
      text: 'Legs',
    },
    {
      value: AreaBody.Arms,
      text: 'Arms',
    },
    {
      value: AreaBody.Hips,
      text: 'Hips',
    },
    {
      value: AreaBody.Equally,
      text: 'They are all equally difficult',
    },
  ],
  isRequired: true,
  type: 'checkbox',
  maxSelectedChoices: 3,
};

const areaBodyGenderExpression = {
  name: 'areaBodyGenderText',
  expression: 'renderAreaBodyGenderText({gender})',
};

function renderAreaBodyGenderText(params: any[]): string {
  if (params === undefined || params === null) return '';
  switch (params[0] as Gender) {
    case Gender.Male:
      return `man`;
    case Gender.Female:
      return `woman`;
  }
}

FunctionFactory.Instance.register('renderAreaBodyGenderText', renderAreaBodyGenderText);

export { AreaBody, areaBodyQuestion, areaBodyGenderExpression };
