import styled from 'styled-components';



const BlackFridayBanner = styled.div`
font-family: Lato, sans-serif;
background-color: #406edf;
padding: 10px;
textAlign: center;

color: white;
display: flex;
justifyContent: center;
alignItems: center;
font-size: 20px;
display: flex;
flex-direction: column;
align-items: center;
@media (max-width: 768px) {
  font-size: 15px;
}

`;

const BlackFridayBannerStrong= styled.strong`
display: flex;
align-items: center;
font-size:24px;
text-align: center;
@media (max-width: 768px) {
  font-size: 18px;
}
`;

const BlackFridayBannerStrongspan =styled.span`
text-decoration:underline;
`;

const BlackFridayBannerP=styled.div`
display:inline;
text-align: center;
`;
const BlackFridayBannerImage=styled.div`
display:inline;
text-align: center;
`;
       
const BlackFridayBannerContainer =styled.div`
display: flex;
text-align: center;
@media (min-width: 768px) {
  margin-left:-50px;
}
`;

const BlackFridayHeader: React.FC = ({  }) => {
  return (
    <>
       <BlackFridayBanner>
       <BlackFridayBannerContainer>
       <BlackFridayBannerImage><img width="48px" src="/bf-icon.png"></img> </BlackFridayBannerImage>
      <BlackFridayBannerStrong>    LIMITED TIME BLACK FRIDAY SALE </BlackFridayBannerStrong> 
      </BlackFridayBannerContainer>
     <BlackFridayBannerP> Save An <BlackFridayBannerStrongspan>Extra 30%</BlackFridayBannerStrongspan> On All Programs & Free Bonuses.</BlackFridayBannerP>
     <BlackFridayBannerP>Discount Automatically Applied At Checkout.</BlackFridayBannerP>
        </BlackFridayBanner>
    </>
  );
};

export  { BlackFridayHeader };

