import styled from "styled-components";

const Card = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  @media (min-width: 416px) {
    padding: 20px;
  }
`;

export {
  Card
}