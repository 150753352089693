
enum BusyLevel {
  NoTime = 'NoTime',
  FairlyBusy = 'FairlyBusy',
  NotBudy = 'NotBusy',
  Flexible = 'Flexible',
}

const busyLevelQuestion = {
  name: 'busyLevel',
  title: 'How busy are you on an typical day?',
  type: 'radiogroup',
  choices: [
    {
      value: BusyLevel.NoTime,
      text: `I barely have any time for myself`,
    },
    {
      value: BusyLevel.FairlyBusy,
      text: `I'm fairly busy, but I try to find time to unwind and relax`,
    },
    {
      value: BusyLevel.NotBudy,
      text: `I'm not too busy and keep time open for different things`,
    },
    {
      value: BusyLevel.Flexible,
      text: `My schedule is very open and flexible`,
    },
  ],
  isRequired: true,
}

export {
  BusyLevel,
  busyLevelQuestion,
}