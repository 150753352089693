import { FunctionFactory } from "survey-core";
import { isNullOrUndefined } from "./utils";

const nameQuestion = {
  name: "name",
  title: "Enter your first name:",
  type: "text",
  placeholder: "John",
  isRequired: true,
  validators: [{
    "type": "text",
    "minLength": 2,
    "text": "Please enter at least your first name.",
  }],
};

function firstName(params: any[]) {
  if (
    isNullOrUndefined(params) || params.length === 0 ||
    isNullOrUndefined(params[0])
  ) return null;
  return params[0].toString().split(" ")[0];
}

function lastName(params: any[]) {
  if (
    isNullOrUndefined(params) || params.length === 0 ||
    isNullOrUndefined(params[1])
  ) return null;
  return params[0].split(" ")[1];
}

const firstNameExpression = {
  name: "firstname",
  expression: "firstName({name})",
};

const lastNameExpression = {
  name: "lastname",
  expression: "lastName({name})",
};

FunctionFactory.Instance.register("firstName", firstName);
FunctionFactory.Instance.register("lastName", lastName);

export { firstName, lastName, nameQuestion, firstNameExpression, lastNameExpression };
