import { useMemo } from "react";
import styled from "styled-components";

const PriceTextBlock = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  text-align:center;
`;

const WhiteSpaceBR = styled.div`
height:0px;
`;

const WhiteSpaceBRBase = styled.div`
color: #98a1a8;
font-style: italic;
`;

const WhitespacePremium=styled.div`
color: #98a1a8;
font-style: italic;
`;

const ProgramPriceMonthlyYearly = styled.div`
  font-family: "Oswald", sans-serif;
  font-size: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
`;

type PriceBlockYearlyProps = {
  current: number;
  original: number;
  hasLimitedSupply: boolean;
  durationWeeks?: number | null;
  is_annual?:  boolean | null;
  
};

const PriceBlockYearly = (props: PriceBlockYearlyProps) => {
  const { current, original, hasLimitedSupply , is_annual } = props;
  const savings = useMemo(() => original - current, [original, current]);

  const hasDiscount = savings > 0;
  
  return (
    <>
      <ProgramPriceMonthlyYearly>
      {hasDiscount ? (
          <>
            ${current}/Month
             
           
          
          </>
        ) : (
          <>
            ${current}
          </>
        )}
      
      </ProgramPriceMonthlyYearly>
      <PriceTextBlock>
      { is_annual && hasLimitedSupply === true &&
        (
          <>
        <WhitespacePremium>All-In-One Fitness & Nutrition App</WhitespacePremium>
        <WhitespacePremium>(billed annually)</WhitespacePremium>
        </>
        )
      }
      { is_annual===false && hasLimitedSupply === true &&

        (
          <>
        <WhitespacePremium>All-In-One Fitness & Nutrition App</WhitespacePremium>
        <WhitespacePremium>(billed monthly)</WhitespacePremium>
        </>
        )
      }

    
      
        {hasDiscount ? (
          <>
          
            {hasLimitedSupply ? (
          <></>
            ) : null}
          </>
        ) : (
          <>
            <WhiteSpaceBRBase> Web-Based </WhiteSpaceBRBase>
            <WhiteSpaceBRBase>(billed once)</WhiteSpaceBRBase>
          </>
        )}

       
   {is_annual ? (
   <>
   
   </> 
   ) : (

    <>
    { hasDiscount? (
      <>
      <WhiteSpaceBR></WhiteSpaceBR>
      </>
    ):(
      <>
      
      </>
    )}
    </>
   )}
      </PriceTextBlock>
    </>
   
  );
};

export type { PriceBlockYearlyProps };

export { PriceBlockYearly };
